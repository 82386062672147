<template>
  <div>
    <header class="fd-app-mode-header">
      <div
        class="fd-app-mode-header-bg"
        :style="{ backgroundImage: 'url(' + fastPlataforma.banner_plataforma + ')', backgroundColor: '#000' }"
      >
        <div class="fd-app-mode-header-overlay">
          <div class="container">
            <div class="fd-app-mode-header-overlay-content">
              <div class="fd-app-mode-header-overlay-content-title">
                <h1 v-if="getUrlParameter('secretaria')">{{ $store.state.fastCarrinho.length ? $store.state.fastCarrinho[0].nome_curso : '' }}</h1>
                <h1 v-else>
                  Checkout
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="fd-app-wrap">
      <div class="container">
        <!-- CURSO DA SECRETARIA -->
        <form-wizard
          v-if="getUrlParameter('id_secretaria_curso')"
          ref="wizard"
          :start-index="currentStep"
          :finish-button-text="'Finalizar'"
          :back-button-text="'Voltar'"
          :next-button-text="'Avançar'"
          :title="fastPessoa.nome_razao + ' ' + fastPessoa.sobrenome_fantasia"
          :subtitle="fastPessoa.email"
          :step-size="'md'"
          :validate-on-back="false"
          shape="circle"
          color="#5da3cc"
          error-color="#e74c3c"
          @on-complete="onComplete"
          @on-validate="handleValidation"
          @on-error="handleErrorMessage"
        >
          <wizard-step 
            slot="step"
            slot-scope="props"
            :tab="props.tab"
            :transition="props.transition"
            :index="props.index"
          />
          <tab-content
            title="Identificação"
            :before-change="validaIdentificacao"
            icon
          >
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <h6 class="m-0 font-weight-bold text-primary">
                      1 - Identificação
                    </h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mt-2 mb-2">
                  <div v-if="usuarioLogado" class="col-12 text-center">
                    <h3 class="text-success">Olá {{fastPessoa.nome_razao}}!</h3>
                    <h4 class="text-secondary">Avance para continuar o processo.</h4>
                  </div>
                  <div v-else class="col-12">
                    <div v-if="modelCadastroUsuario.cadastro_finalizado" class="row">
                      <div class="col-12 text-center">
                        <h3 class="text-success">Cadastro efetuado!</h3>
                        <h4 class="text-secondary">Acesse seu email e confirme o cadastro para continuar o processo.</h4>
                      </div>
                    </div>
                    <div v-else class="row">
                      <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="text-secondary">Faça login</h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12">
                            <b-form @submit="fastAutenticar">
                              <b-form-group
                                id="input-group-email-login"
                                label=""
                                label-for="input-email-login"
                                description="Insira seu email cadastrado."
                              >
                                <b-form-input
                                  id="input-email-login"
                                  type="email"
                                  placeholder="Email"   
                                  v-model="modelLoginUsuario.fast_usuario.email"                               
                                  required
                                ></b-form-input>                                
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-senha-login"
                                label=""
                                label-for="input-senha-login"
                                description="Insira sua senha de acesso."
                              >
                                <b-form-input
                                  id="input-senha-login"
                                  type="password"
                                  placeholder="Senha"
                                  v-model="modelLoginUsuario.fast_usuario.password" 
                                  required
                                ></b-form-input>
                                
                              </b-form-group>
                              <div class="text-center">
                                <small class="text-danger"> {{modelLoginUsuario.fast_usuario.error}} </small>
                                <b-button v-if="modelLoginUsuario.fast_usuario.autenticando" type="button" variant="primary"> 
                                  <b-icon icon="gear-fill" animation="spin" font-scale="1"></b-icon>
                                  <span class="ml-2">Autenticando</span>
                                </b-button>
                                <b-button v-else-if="validaTodosCamposLogin()" type="submit" variant="primary" class="blink_me"><b-icon icon="person-check-fill"></b-icon> Autenticar</b-button>
                              </div>
                              
                            </b-form>
                          </div>
                        </div>                        
                      </div>
                      <div v-if="!getUrlParameter('secretaria')" class="col-sm-12 col-md-12 col-lg-3 mb-4">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="text-secondary">Não é cadastrado?</h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12">
                            <b-form @submit.prevent="cadastrarUsuarioCompleto()">

                              <b-form-group
                                id="input-group-nome-cadastro"
                                label=""
                                label-for="input-nome-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-nome-cadastro"
                                  v-model="modelCadastroUsuario.fast_plataforma_usuario.nome_usuario"
                                  type="text"
                                  placeholder="Nome completo"
                                  @keyup="validaNome"
                                  :class="modelCadastroUsuario.validacaoNome.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  autocomplete="no"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoNome.class"> {{modelCadastroUsuario.validacaoNome.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-email-cadastro"
                                label=""
                                label-for="input-email-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-email-cadastro"
                                  v-model="modelCadastroUsuario.fast_usuario.email"
                                  type="email"
                                  placeholder="Email"
                                  @keyup="validaEmail"
                                  :class="modelCadastroUsuario.validacaoEmail.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  autocomplete="no"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoEmail.class"> {{modelCadastroUsuario.validacaoEmail.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-senha-cadastro"
                                label=""
                                label-for="input-senha-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-senha-cadastro"
                                  v-model="modelCadastroUsuario.fast_usuario.password"
                                  :class="modelCadastroUsuario.validacaoSenha.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @keyup="validaSenha"
                                  type="password"
                                  placeholder="Senha"
                                  autocomplete="no"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoSenha.class"> {{modelCadastroUsuario.validacaoSenha.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-senha2-cadastro"
                                label=""
                                label-for="input-senha2-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-senha2-cadastro"
                                  v-model="modelCadastroUsuario.fast_usuario.password2"
                                  :class="modelCadastroUsuario.validacaoSenha.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @keyup="validaSenha"
                                  type="password"
                                  placeholder="Confirmar senha"
                                  autocomplete="no"
                                  required
                                ></b-form-input>    
                                <small v-if="modelCadastroUsuario.validacaoSenha.invalid" :class="modelCadastroUsuario.validacaoSenha.class"> Confirme a senha </small>
                                <small v-else class="text-success">Senha confirmada</small>      
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-cpf-cadastro"
                                label=""
                                label-for="input-cpf-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-cpf-cadastro"
                                  v-model="modelCadastroUsuario.fast_pessoa.cpf_cnpj"
                                  :class="modelCadastroUsuario.validacaoCpf.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @keyup="validaCpf"
                                  type="text"
                                  placeholder="CPF/CNPJ"
                                  autocomplete="no"
                                  v-mask="[
                                    '###.###.###-##',
                                    '##.###.###/####-##',
                                  ]"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoCpf.class"> {{modelCadastroUsuario.validacaoCpf.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-datanascimento-cadastro"
                                label=""
                                label-for="input-datanascimento-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-datanascimento-cadastro"
                                  v-model="modelCadastroUsuario.fast_pessoa.datanascimento_abertura"
                                  :class="modelCadastroUsuario.validacaoDataNascimento.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @change="validaDataNascimento"
                                  autocomplete="no"
                                  type="date"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoDataNascimento.class"> {{modelCadastroUsuario.validacaoDataNascimento.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-telefone-cadastro"
                                label=""
                                label-for="input-telefone-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-telefone-cadastro"
                                  v-model="modelCadastroUsuario.fast_pessoa_telefone.telefone"
                                  :class="modelCadastroUsuario.validacaoTelefone.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @keyup="validaTelefone"
                                  type="text"
                                  placeholder="(00) 00000-0000"
                                  v-mask="[
                                    '(##) ####-####',
                                    '(##) #####-####',
                                  ]"
                                  autocomplete="no"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoTelefone.class"> {{modelCadastroUsuario.validacaoTelefone.mensagem}} </small>
                                
                              </b-form-group>

                              <div class="text-center">
                                <b-button v-if="modelCadastroUsuario.cadastrando" type="button" variant="primary"> 
                                  <b-icon icon="gear-fill" animation="spin" font-scale="1"></b-icon>
                                  <span class="ml-2">Cadastrando</span>
                                </b-button>
                                <b-button v-else-if="validaTodosCampos()" type="submit" variant="primary" class="blink_me"><b-icon icon="person-check-fill"></b-icon> Cadastrar</b-button>
                              </div>
                              
                            </b-form>
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content
            title="Turmas"
            :before-change="validaPrimeiroPasso"
            icon
          >
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <h6 class="m-0 font-weight-bold text-primary">
                      2 - Turmas disponíveis
                    </h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mt-2 mb-2">                  
                  <div
                    v-if="fastSteps.step1.curso.id_secretaria_curso"
                    class="col-sm-12 col-md-12 col-lg-4"
                  >
                    <h5 v-if="!fastSteps.step1.turma.loading" class="text-secondary">O curso escolhido possui {{fastSecretariaTurmasTotal.length}} turmas disponíveis</h5>                     

                    <select
                      v-if="fastSteps.step1.turma.loading"
                      class="form-control"
                    >
                      <option value="">
                        -- Carregando turmas --
                      </option>
                    </select>                     
                    <select
                      v-else-if="fastSecretariaTurmasTotal.length"
                      v-model="fastSteps.step1.turma.id_turma"
                      class="form-control"
                    >
                      <option
                        value="0"
                        selected
                      >
                        -- Selecione uma turma --
                      </option>
                      <option
                        v-for="turma in fastSecretariaTurmasTotal"
                        :key="turma.id_turma"
                        :value="turma.id_turma"
                      >
                        {{ turma.sigla_turma }}
                        <span v-if="turma.turno == 1"> - Manhã</span>
                        <span v-else-if="turma.turno == 2"> - Tarde</span>
                        <span v-else-if="turma.turno == 3"> - Noite</span>
                        <span v-else> - Sem turno</span>
                      </option>
                    </select>  
                    <select
                      v-else
                      class="form-control"
                    >
                      <option value="">
                        -- Nenhuma turma disponível --
                      </option>
                    </select>    
                  </div>
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content
            title="Planos"
            :before-change="validaSegundoPasso"
            icon
          >
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <h6 class="m-0 font-weight-bold text-primary">
                      3 - Opções de plano
                    </h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mt-2 mb-2">
                  <div class="col-sm-12 col-md-12 col-lg-4">
                    <div class="list-group">
                      <div
                        class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                        :class="[fastSteps.step2.plano == 'curso' ? 'active' : '']"
                        @click="fastSteps.step2.plano = 'curso'"
                      >
                        <div class="row mb-4">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h5>Curso completo</h5>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <small>Valor: R$ {{ formataPreco(fastSteps.step1.curso.preco) }}</small>
                          </div>                                    
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="fastSteps.step1.curso.venda_modulo"
                      class="list-group"
                    >
                      <div
                        class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                        :class="[fastSteps.step2.plano == 'modulo' ? 'active' : '']"
                        @click="fastSteps.step2.plano = 'modulo'"
                      >
                        <div class="row mb-4">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h5>Módulos</h5>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <small>Valor: variável</small>
                          </div>                                    
                        </div>
                      </div>
                    </div>
                    <div
                      v-if="fastSteps.step1.curso.venda_unidade_curricular"
                      class="list-group"
                    >
                      <div
                        class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                        :class="[fastSteps.step2.plano == 'unidade' ? 'active' : '']"
                        @click="fastSteps.step2.plano = 'unidade'"
                      >                                
                        <div class="row mb-4">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h5>Unidades curriculares</h5>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <small>Valor: variável</small>
                          </div>                                    
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-8">
                    <div class="list-group">
                      <div
                        v-if="fastSteps.step2.plano == 'curso'"
                        class="flex-column align-items-start"
                      >                                
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                            <h5 class="text-info">
                              Plano escolhido: Curso completo
                            </h5> 
                            <p>Nessa plano você será matriculado na turma escolhida e terá acesso a todos os módulos do curso.</p>
                            <h6>Módulos:</h6>
                            <div v-if="fastSteps.step2.modulo.loading">
                              <h6>Carregando...</h6>
                            </div> 
                            <ul v-else-if="fastSecretariaModulosTotal.length">
                              <li
                                v-for="modulo in fastSecretariaModulosTotal"
                                :key="modulo.id_programa_turma"
                                :value="modulo.id_programa_turma"
                                style="list-style-position: inside"
                              >
                                {{ modulo.sigla_programa }}
                                <span v-if="modulo.data_inicio">{{
                                  " - " +
                                    modulo.data_inicio.split("-")[2] +
                                    "/" +
                                    modulo.data_inicio.split("-")[1] +
                                    "/" +
                                    modulo.data_inicio.split("-")[0]
                                }}</span>
                                <span v-else> - Sem data</span> 
                                <span v-if="modulo.creditos">{{
                                  " - " +
                                    modulo.creditos + " créditos"
                                }}</span>
                              </li>
                            </ul>
                            <div v-else>
                              <h6>Nenhum módulo encontrado</h6>
                            </div>
                          </div>
                        </div>  
                      </div>
                      <div
                        v-if="fastSteps.step2.plano == 'modulo'"
                        class="flex-column align-items-start"
                      > 
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                            <h5 class="text-info">
                              Plano escolhido: Módulo do curso
                            </h5> 
                            <p>Nesse plano você pode optar por escolher módulos específicos.</p>     
                            <div class="form-check d-flex align-items-center">
                              <input
                                id="checkRequisitosObrigatorios"
                                v-model="fastSteps.step2.modulo.requisitos_obrigatorios"
                                type="checkbox"
                                class="form-check-input"
                              >
                              <label
                                class="form-check-label text-danger"
                                for="checkRequisitosObrigatorios"
                              >
                                Exibir requisitos obrigatórios                                        
                              </label>
                            </div>                                 
                          </div>
                        </div>                                
                        <div class="row">                                    
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                            <div
                              v-if="fastSteps.step2.modulo.loading"
                              class="list-group-item list-group-item-action flex-column align-items-start"
                            >                                
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <span class="text-info">Carregando...</span>
                                </div>
                              </div>
                            </div>
                            <div v-else-if="fastSecretariaModulosTotal.length">
                              <div
                                v-for="modulo in fastSecretariaModulosTotal"
                                :key="modulo.id_programa_turma"
                              >
                                <div
                                  class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                                  :class="[fastSteps.step2.modulo.lista.includes(modulo.id_secretaria_curso_programa) ? 'active' : '']"
                                  @click="selecionaModulo(modulo)"
                                >                                
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12">
                                      <div>
                                        <b>{{ modulo.sigla_programa }}</b>
                                        <b v-if="modulo.data_inicio">{{
                                          " - " +
                                            modulo.data_inicio.split("-")[2] +
                                            "/" +
                                            modulo.data_inicio.split("-")[1] +
                                            "/" +
                                            modulo.data_inicio.split("-")[0]
                                        }}</b>
                                        <b v-else> - Sem data definida</b>                                                  
                                        <p
                                          v-if="modulo.creditos"
                                          class="mb-0"
                                        >
                                          <small>{{ "Créditos: " + modulo.creditos }}</small>
                                        </p>
                                        <p
                                          v-if="modulo.preco"
                                          class="mb-0"
                                        >
                                          <small>{{ "Valor: R$ " + formataPreco(modulo.preco) }}</small>
                                        </p>
                                        <p
                                          v-else
                                          class="mb-0"
                                        >
                                          <small>Sem valor definido</small>
                                        </p>
                                      </div>
                                      <p
                                        v-if="modulo.id_pai && fastSteps.step2.modulo.requisitos_obrigatorios"
                                        class="text-danger"
                                      >
                                        <small>Pré-requisito:
                                          <span
                                            v-for="modulo2 in fastSecretariaModulosTotal"
                                            :key="modulo2.id_programa_turma"
                                          >
                                            <b v-if="modulo.id_pai == modulo2.id_secretaria_curso_programa">{{ modulo2.sigla_programa }}</b>
                                          </span>
                                        </small>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>    
                              <div class="text-center">
                                <p class="text-info">
                                  Valor total: R$ {{ formataPreco(calculaPrecoTotal(fastSteps.step2.modulo.lista, 'modulo')) }}
                                </p>  
                              </div>                                    
                            </div>                                      
                            <div
                              v-else
                              class="list-group-item list-group-item-action flex-column align-items-start"
                            >                                
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <span class="info">Nenhum módulo encontrado</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>  
                      </div>
                      <div
                        v-else-if="fastSteps.step2.plano == 'unidade'"
                        class="flex-column align-items-start"
                      > 
                        <div class="row">
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                            <h5 class="text-info">
                              Plano escolhido: Unidade curricular
                            </h5> 
                            <p>Nesse plano você pode escolher as unidades curriculares que deseja.</p>    
                            <div class="form-check d-flex align-items-center">
                              <input
                                id="checkRequisitosObrigatorios"
                                v-model="fastSteps.step2.unidade.requisitos_obrigatorios"
                                type="checkbox"
                                class="form-check-input"
                              >
                              <label
                                class="form-check-label text-danger"
                                for="checkRequisitosObrigatorios"
                              >
                                Exibir requisitos obrigatórios                                        
                              </label>
                            </div>                                  
                          </div>
                        </div>                               
                        <div class="row">                                    
                          <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                            <div
                              v-if="fastSteps.step2.modulo.loading"
                              class="list-group-item list-group-item-action flex-column align-items-start"
                            >                                
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <span class="text-info">Carregando...</span>
                                </div>
                              </div>
                            </div>
                            <div
                              v-else-if="fastSecretariaModulosTotal.length"
                              class="mt-4"
                            >
                              <div
                                v-for="modulo in fastSecretariaModulosTotal"
                                :key="modulo.id_programa_turma"
                                class="mb-4"
                              >
                                <div class="mb-2">                                
                                  <div class="row">
                                    <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                      <h5>
                                        {{ modulo.sigla_programa }} - Unidades curriculares
                                      </h5>
                                      <p
                                        v-if="modulo.id_pai && fastSteps.step2.modulo.requisitos_obrigatorios"
                                        class="text-danger mb-0"
                                      >
                                        Pré-requisito:
                                        <span
                                          v-for="modulo2 in fastSecretariaModulosTotal"
                                          :key="modulo2.id_programa_turma"
                                        >
                                          <b v-if="modulo.id_pai == modulo2.id_secretaria_curso_programa">{{ modulo2.sigla_programa }}</b>
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div
                                      v-if="fastSteps.step2.unidade.loading"
                                      class="col-12"
                                    >
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                          <span class="text-info">Carregando...</span>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-if="modulo.unidades_curriculares.length"
                                      class="col-12"
                                    >
                                      <div
                                        v-for="(unidade, index) in modulo.unidades_curriculares"
                                        :key="unidade.id_secretaria_curso_programa_unidade"
                                      >
                                        <div
                                          class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                                          :class="[fastSteps.step2.unidade.lista.includes(unidade.id_secretaria_curso_programa_unidade) ? 'active' : '']"
                                          @click="selecionaUnidade(unidade)"
                                        >                                
                                          <div class="row">
                                            <div class="col-sm-12 col-md-12 col-lg-12">
                                              <div>
                                                <b>{{ "UC " + (index + 1) + " - " + unidade.nome_curso }}</b>
                                                <b v-if="unidade.data_inicio">{{
                                                  " - " +
                                                    unidade.data_inicio.split("-")[2] +
                                                    "/" +
                                                    unidade.data_inicio.split("-")[1] +
                                                    "/" +
                                                    unidade.data_inicio.split("-")[0]
                                                }}</b>
                                                <b v-else> - Sem data definida</b>

                                                <p
                                                  v-if="unidade.creditos"
                                                  class="mb-0"
                                                >
                                                  <small>{{ "Créditos: " + unidade.creditos }}</small>
                                                </p>
                                                <p
                                                  v-if="unidade.preco"
                                                  class="mb-0"
                                                >
                                                  <small>{{ "Valor: R$ " + formataPreco(unidade.preco) }}</small>
                                                </p>
                                                <p
                                                  v-else
                                                  class="mb-0"
                                                >
                                                  <small>Sem valor definido</small>
                                                </p>
                                              </div>
                                              <p
                                                v-if="unidade.id_pai && fastSteps.step2.unidade.requisitos_obrigatorios"
                                                class="text-danger mb-0"
                                              >
                                                <small>Pré-requisito:
                                                  <span
                                                    v-for="unidade2 in fastSecretariaUnidadesTotal"
                                                    :key="unidade2.id_secretaria_curso_programa_unidade"
                                                  >
                                                    <b v-if="unidade.id_pai == unidade2.id_secretaria_curso_programa_unidade">{{ unidade2.nome_curso }}</b>
                                                  </span></small>
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      v-else
                                      class="col-12"
                                    >
                                      <div class="row">
                                        <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                          <div class="list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2">
                                            <span class="info">Nenhuma unidade curricular encontrada</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>    
                              <div class="text-center">
                                <p class="text-info">
                                  Valor total: R$ {{ formataPreco(calculaPrecoTotal(fastSteps.step2.unidade.lista, 'unidade')) }}
                                </p>  
                              </div>                                    
                            </div>                                      
                            <div
                              v-else
                              class="list-group-item list-group-item-action flex-column align-items-start"
                            >                                
                              <div class="row">
                                <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                                  <span class="info">Nenhum módulo encontrado</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> 
                      </div>                                
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content          
            title="Finalizar"
            icon
          >            
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <h6 class="m-0 font-weight-bold text-primary">
                      4 - Finalizar
                    </h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mt-2 mb-2">
                  <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h4 class="text-success">
                      Matrícula solicitada com sucesso!
                    </h4>     
                    <h5 class="text-secondary">Em breve a secretaria da plataforma entrará em contato por email ou por telefone.</h5>
                  </div>
                </div>                      
              </div>
            </div>
          </tab-content>         
        </form-wizard>
        <!-- /CURSO DA SECRETARIA -->
        <!-- CURSO LIVRE -->
        <form-wizard
          v-else
          ref="wizard"
          :start-index="currentStep"
          :finish-button-text="'Finalizar'"
          :back-button-text="'Voltar'"
          :next-button-text="'Avançar'"
          :title="fastPessoa.nome_razao + ' ' + fastPessoa.sobrenome_fantasia"
          :subtitle="fastPessoa.email"
          :step-size="'md'"
          :validate-on-back="false"
          shape="circle"
          color="#5da3cc"
          error-color="#e74c3c"
          @on-complete="onComplete"
          @on-validate="handleValidation"
          @on-error="handleErrorMessage"
        >
          <wizard-step 
            slot="step"
            slot-scope="props"
            :tab="props.tab"
            :transition="props.transition"
            :index="props.index"
          />
          <tab-content
            title="Identificação"
            :before-change="validaIdentificacao"
            icon
          >
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <h6 class="m-0 font-weight-bold text-primary">
                      1 - Identificação
                    </h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mt-2 mb-2">
                  <div v-if="usuarioLogado" class="col-12 text-center">
                    <h3 class="text-success">Olá {{fastPessoa.nome_razao}}!</h3>
                    <h4 class="text-secondary">Avance para continuar o processo.</h4>
                  </div>
                  <div v-else class="col-12">
                    <div v-if="modelCadastroUsuario.cadastro_finalizado" class="row">
                      <div class="col-12 text-center">
                        <h3 class="text-success">Cadastro efetuado!</h3>
                        <h4 class="text-secondary">Acesse seu email e confirme o cadastro para continuar o processo.</h4>
                      </div>
                    </div>
                    <div v-else class="row">
                      <div class="col-sm-12 col-md-12 col-lg-3 mb-4">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="text-secondary">Faça login</h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12">
                            <b-form @submit="fastAutenticar">
                              <b-form-group
                                id="input-group-email-login"
                                label=""
                                label-for="input-email-login"
                                description="Insira seu email cadastrado."
                              >
                                <b-form-input
                                  id="input-email-login"
                                  type="email"
                                  placeholder="Email"   
                                  v-model="modelLoginUsuario.fast_usuario.email"                               
                                  required
                                ></b-form-input>                                
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-senha-login"
                                label=""
                                label-for="input-senha-login"
                                description="Insira sua senha de acesso."
                              >
                                <b-form-input
                                  id="input-senha-login"
                                  type="password"
                                  placeholder="Senha"
                                  v-model="modelLoginUsuario.fast_usuario.password" 
                                  required
                                ></b-form-input>
                                
                              </b-form-group>
                              <div class="text-center">
                                <small class="text-danger"> {{modelLoginUsuario.fast_usuario.error}} </small>
                                <b-button v-if="modelLoginUsuario.fast_usuario.autenticando" type="button" variant="primary"> 
                                  <b-icon icon="gear-fill" animation="spin" font-scale="1"></b-icon>
                                  <span class="ml-2">Autenticando</span>
                                </b-button>
                                <b-button v-else-if="validaTodosCamposLogin()" type="submit" variant="primary" class="blink_me"><b-icon icon="person-check-fill"></b-icon> Autenticar</b-button>
                              </div>
                              
                            </b-form>
                          </div>
                        </div>                        
                      </div>
                      <div v-if="!getUrlParameter('id_secretaria_curso')" class="col-sm-12 col-md-12 col-lg-3 mb-4">
                        <div class="row">
                          <div class="col-12">
                            <h5 class="text-secondary">Não é cadastrado?</h5>
                          </div>
                        </div>
                        <div class="row mt-2">
                          <div class="col-12">
                            <b-form @submit.prevent="cadastrarUsuarioCompleto()">

                              <b-form-group
                                id="input-group-nome-cadastro"
                                label=""
                                label-for="input-nome-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-nome-cadastro"
                                  v-model="modelCadastroUsuario.fast_plataforma_usuario.nome_usuario"
                                  type="text"
                                  placeholder="Nome completo"
                                  @keyup="validaNome"
                                  :class="modelCadastroUsuario.validacaoNome.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  autocomplete="no"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoNome.class"> {{modelCadastroUsuario.validacaoNome.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-email-cadastro"
                                label=""
                                label-for="input-email-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-email-cadastro"
                                  v-model="modelCadastroUsuario.fast_usuario.email"
                                  type="email"
                                  placeholder="Email"
                                  @keyup="validaEmail"
                                  :class="modelCadastroUsuario.validacaoEmail.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  autocomplete="no"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoEmail.class"> {{modelCadastroUsuario.validacaoEmail.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-senha-cadastro"
                                label=""
                                label-for="input-senha-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-senha-cadastro"
                                  v-model="modelCadastroUsuario.fast_usuario.password"
                                  :class="modelCadastroUsuario.validacaoSenha.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @keyup="validaSenha"
                                  type="password"
                                  placeholder="Senha"
                                  autocomplete="no"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoSenha.class"> {{modelCadastroUsuario.validacaoSenha.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-senha2-cadastro"
                                label=""
                                label-for="input-senha2-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-senha2-cadastro"
                                  v-model="modelCadastroUsuario.fast_usuario.password2"
                                  :class="modelCadastroUsuario.validacaoSenha.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @keyup="validaSenha"
                                  type="password"
                                  placeholder="Confirmar senha"
                                  autocomplete="no"
                                  required
                                ></b-form-input>    
                                <small v-if="modelCadastroUsuario.validacaoSenha.invalid" :class="modelCadastroUsuario.validacaoSenha.class"> Confirme a senha </small>
                                <small v-else class="text-success">Senha confirmada</small>      
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-cpf-cadastro"
                                label=""
                                label-for="input-cpf-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-cpf-cadastro"
                                  v-model="modelCadastroUsuario.fast_pessoa.cpf_cnpj"
                                  :class="modelCadastroUsuario.validacaoCpf.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @keyup="validaCpf"
                                  type="text"
                                  placeholder="CPF/CNPJ"
                                  autocomplete="no"
                                  v-mask="[
                                    '###.###.###-##',
                                    '##.###.###/####-##',
                                  ]"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoCpf.class"> {{modelCadastroUsuario.validacaoCpf.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-datanascimento-cadastro"
                                label=""
                                label-for="input-datanascimento-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-datanascimento-cadastro"
                                  v-model="modelCadastroUsuario.fast_pessoa.datanascimento_abertura"
                                  :class="modelCadastroUsuario.validacaoDataNascimento.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @change="validaDataNascimento"
                                  autocomplete="no"
                                  type="date"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoDataNascimento.class"> {{modelCadastroUsuario.validacaoDataNascimento.mensagem}} </small>
                                
                              </b-form-group>

                              <b-form-group
                                id="input-group-telefone-cadastro"
                                label=""
                                label-for="input-telefone-cadastro"
                                description=""
                              >
                                <b-form-input
                                  id="input-telefone-cadastro"
                                  v-model="modelCadastroUsuario.fast_pessoa_telefone.telefone"
                                  :class="modelCadastroUsuario.validacaoTelefone.invalid ? 'border border-danger text-danger' : 'border border-success text-success'"
                                  @keyup="validaTelefone"
                                  type="text"
                                  placeholder="(00) 00000-0000"
                                  v-mask="[
                                    '(##) ####-####',
                                    '(##) #####-####',
                                  ]"
                                  autocomplete="no"
                                  required
                                ></b-form-input>
                                <small :class="modelCadastroUsuario.validacaoTelefone.class"> {{modelCadastroUsuario.validacaoTelefone.mensagem}} </small>
                                
                              </b-form-group>

                              <div class="text-center">
                                <b-button v-if="modelCadastroUsuario.cadastrando" type="button" variant="primary"> 
                                  <b-icon icon="gear-fill" animation="spin" font-scale="1"></b-icon>
                                  <span class="ml-2">Cadastrando</span>
                                </b-button>
                                <b-button v-else-if="validaTodosCampos()" type="submit" variant="primary" class="blink_me"><b-icon icon="person-check-fill"></b-icon> Cadastrar</b-button>
                              </div>
                              
                            </b-form>
                          </div>
                        </div>                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content
            title="Pagamento"
            :before-change="validaSegundoPassoPagamento"
            icon
          >
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <h6 class="m-0 font-weight-bold text-primary">
                      2 - Pagamento
                    </h6>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row mt-2 mb-2">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <h4 class="text-secondary">
                      Plano de pagamento
                    </h4>  
                    <select
                      v-if="fastSteps.step3.plano.loading"
                      class="form-control"
                    >
                      <option value="">
                        Carregando planos...
                      </option>
                    </select>                        
                    <select
                      v-else-if="fastSecretariaPlanoPagamento.length"
                      v-model="fastSteps.step3.plano.id_plano_pagamento"
                      class="form-control"
                      @change="verificaConfiguracaoPagamento()"                                  
                    >
                      <option value="0">
                        -- Selecione um plano de pagamento --
                      </option>
                      <option
                        v-for="plano in fastSecretariaPlanoPagamento"
                        :key="plano.id_plano_pagamento"
                        :value="plano.id_plano_pagamento"
                      >
                        {{ plano.nome_plano }}
                      </option>
                    </select>  
                    <select
                      v-else
                      class="form-control"
                    >
                      <option value="">
                        -- Nenhum plano de pagamento encontrado --
                      </option>
                    </select>    
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <h4 class="text-secondary">
                      Tipo de pagamento
                    </h4>  
                    <select
                      v-if="fastSteps.step3.tipo.loading"
                      class="form-control"
                    >
                      <option value="">
                        Carregando tipos...
                      </option>
                    </select>                        
                    <select
                      v-else-if="fastSecretariaTipoPagamento.length"
                      v-model="fastSteps.step3.tipo.id_tipo_pagamento"
                      class="form-control"
                      @change="verificaConfiguracaoPagamento()"
                    >
                      <option value="0">
                        -- Selecione um tipo de pagamento --
                      </option>
                      <option
                        v-for="tipo in fastSecretariaTipoPagamento"
                        :key="tipo.id_tipo_pagamento"
                        :value="tipo.id_tipo_pagamento"
                      >
                        {{ tipo.nome_tipo_pagamento }}
                        <span v-if="tipo.forma_pagamento"> - {{ formataFormaPagamento(tipo.forma_pagamento) }}</span>
                      </option>
                    </select>  
                    <select
                      v-else
                      class="form-control"
                    >
                      <option value="">
                        -- Nenhum tipo de pagamento encontrado --
                      </option>
                    </select>    
                  </div>
                </div>
                <div
                  v-if="fastSteps.step3.plano.id_plano_pagamento && fastSteps.step3.tipo.id_tipo_pagamento"
                  class="row mt-4 mb-2"
                >
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <div class="row mt-2">
                      <div class="col-sm-12 col-md-12 col-lg-6">
                        <div class="row mb-4">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h5>Detalhes do plano</h5>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-2">
                            <p class="mb-0">
                              <span>Nome do plano:</span> {{ fastSteps.step3.plano.nome_plano }}
                            </p>
                            <p class="mb-0">
                              <span>Observações:</span> {{ fastSteps.step3.plano.obs }}
                            </p>
                            <p class="mb-0">
                              <span>Dia/mês: </span> 
                              <span v-if="fastSteps.step3.plano.dia_mes == 'D'"> Dia </span>
                              <span v-else-if="fastSteps.step3.plano.dia_mes == 'M'"> Mês </span>
                            </p>
                            <p class="mb-0">
                              <span>Periodicidade: </span> 
                              <span v-if="fastSteps.step3.plano.periocidade_cada_unidade == 0">Indeterminado</span>
                              <span v-else-if="fastSteps.step3.plano.dia_mes == 'D'">
                                <span v-if="fastSteps.step3.plano.periocidade_cada_unidade == 1">Todo dia</span>
                                <span v-else>A cada {{ fastSteps.step3.plano.periocidade_cada_unidade }} dias</span>
                              </span>
                              <span v-else-if="fastSteps.step3.plano.dia_mes == 'M'">                                          
                                <span v-if="fastSteps.step3.plano.periocidade_cada_unidade == 1">Todo mês</span>
                                <span v-else>A cada {{ fastSteps.step3.plano.periocidade_cada_unidade }} meses</span>
                              </span>
                            </p>
                            <p class="mb-0">
                              <span>Tempo determinado:</span> 
                              <span v-if="fastSteps.step3.plano.tempo_determinado == 0">Indeterminado</span>
                              <span v-else-if="fastSteps.step3.plano.dia_mes == 'D'">
                                {{ fastSteps.step3.plano.tempo_determinado }} dias
                              </span>
                              <span v-else-if="fastSteps.step3.plano.dia_mes == 'M'">                                          
                                {{ fastSteps.step3.plano.tempo_determinado }} meses
                              </span>
                            </p>
                          </div>                                    
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <div class="row mb-4">
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <h5>Valores do plano</h5>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 mt-2">                                      
                            <p class="mb-0">
                              <span>Subtotal: R$ {{ formataPreco(fastSteps.step3.invoice.subtotal) }}</span>
                              <span
                                v-if="fastSteps.step3.invoice.acrescimo > 0"
                                class="text-danger"
                              > + R$ {{ formataPreco(fastSteps.step3.invoice.acrescimo) }} (acréscimo)</span>
                              <span
                                v-if="fastSteps.step3.invoice.desconto_total > 0"
                                class="text-success"
                              > - R$ {{ formataPreco(fastSteps.step3.invoice.desconto_total) }} (desconto)</span>
                              <span v-if="fastSteps.step3.plano.parcelas.length">
                                <span v-if="fastSteps.step3.plano.parcelas[0].taxa_boleto || fastSteps.step3.plano.parcelas[0].taxa_cartao || fastSteps.step3.plano.parcelas[0].outras_taxas" class="text-danger"> + taxas das parcelas</span>
                              </span>
                            </p>
                            <p>
                              <span>Total: R$ {{ formataPreco(fastSteps.step3.invoice.total) }}</span>
                            </p>
                            <p
                              v-if="fastSteps.step3.plano.parcelas.length"
                              class="mb-0 d-none"
                            >
                              {{ fastSteps.step3.plano.parcelas.length }} x R$ {{ formataPreco(fastSteps.step3.plano.parcelas[0].valor_parcela) }}
                              <span v-if="fastSteps.step3.plano.tempo_determinado == 0"> por tempo indeterminado</span>
                            </p>
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12 text-right">
                            <button
                              class="btn btn-sm btn-primary"
                              @click="showModal('modalDefinirPrimeiraParcela')"
                            >
                              <small>Definir primeira parcela</small>
                            </button>                                      
                          </div>
                          <div class="col-sm-12 col-md-12 col-lg-12">
                            <div
                              v-if="fastSteps.step3.plano.parcelas.length"
                              class="mt-2 list-group"
                            >
                              <div
                                v-for="(parcela, index) in fastSteps.step3.plano.parcelas"
                                :key="index"
                                class="mb-2 text-secondary list-group-item list-group-item-action flex-column align-items-start class-pointer mb-2"
                              >
                                <b class="text-info">Parcela {{ index + 1 }} - {{ parcela.data_formatada }} - R$ {{ formataPreco(parcela.valor_parcela) }}
                                  <span
                                    v-if="fastSteps.step3.invoice.taxa_boleto > 0"
                                    class="text-danger"
                                  > + R$ {{ formataPreco(fastSteps.step3.invoice.taxa_boleto) }} (taxa boleto)</span>
                                  <span
                                    v-if="fastSteps.step3.invoice.taxa_cartao > 0"
                                    class="text-danger"
                                  > + R$ {{ formataPreco(fastSteps.step3.invoice.taxa_cartao) }} (taxa cartão)</span>
                                  <span
                                    v-if="fastSteps.step3.invoice.outras_taxas > 0"
                                    class="text-danger"
                                  > + R$ {{ formataPreco(fastSteps.step3.invoice.outras_taxas) }} (outras taxas)</span>
                                  <span v-if="fastSteps.step3.invoice.taxa_boleto > 0 || fastSteps.step3.invoice.taxa_cartao > 0 || fastSteps.step3.invoice.outras_taxas > 0"> = R$ {{ formataPreco(parseFloat(parcela.valor_parcela) + parseFloat(fastSteps.step3.invoice.taxa_boleto ? fastSteps.step3.invoice.taxa_boleto : 0) + parseFloat(fastSteps.step3.invoice.taxa_cartao ? fastSteps.step3.invoice.taxa_cartao : 0) + parseFloat(fastSteps.step3.invoice.outras_taxas ? fastSteps.step3.invoice.outras_taxas : 0)) }}</span>
                                </b>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>
          <tab-content
            title="Finalizar"
            icon
          >
            <div class="card shadow mb-4">
              <div class="card-header py-3">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12">
                    <h6 class="m-0 font-weight-bold text-primary">
                      3 - Finalizar
                    </h6>
                  </div>
                </div>
              </div>
              <div v-if="!fastSteps.step3.invoice.id_invoice" class="card-body">
                <div class="row mt-2 mb-2">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="bg-pay p-3"> 
                        <h4 class="text-secondary">Dados do cliente</h4>
                        <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Nome</span> <span>{{fastSteps.step3.invoice.nome_responsavel}}</span> </div>
                        <div class="d-flex justify-content-between mt-2"> <span class="fw-500">CPF/CPNJ</span> <span>{{fastSteps.step3.invoice.cpf_responsavel}}</span> </div>
                        <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Data de nascimento</span> <span>{{fastPessoa.datanascimento_abertura}}</span> </div>
                        <div class="d-flex justify-content-between mt-2"> <span class="fw-500">Telefone </span> <span>{{fastPessoa.telefone}}</span> </div>
                        <hr>
                    </div>
                  </div>
                </div>
                <div class="row mt-2 mb-2">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="bg-pay p-3"> 
                      <h4 class="text-secondary">Cursos</h4>
                        <div class="d-flex justify-content-between mt-2" v-for="(i, index) in fastSteps.step2.cursos_livres" :key="index"> 
                          <span class="fw-500">{{i.nome_item}}</span> <span>+ R$ {{ formataPreco(i.valor_item) }}</span> 
                        </div>
                        <div class="d-flex justify-content-between mt-2 font-weight-bold"> <span class="fw-500">Total</span> <span>R$ {{formataPreco(fastSteps.step2.total)}}</span> </div>

                        <hr>
                    </div>                    
                  </div>
                </div>
                <div class="row mt-2 mb-2">
                  <div class="col-sm-12 col-md-12 col-lg-6">
                    <div class="bg-pay p-3"> 
                      <h4 class="text-secondary">Forma de pagamento</h4>
                      <div class="mt-2"> 
                        {{fastSteps.step3.invoice.parcelas.length}}x no 
                        <span v-if="fastSteps.step3.tipo.forma_pagamento == 'B'">boleto bancário</span> 
                        <span v-else-if="fastSteps.step3.tipo.forma_pagamento == 'C'">cartão de crédito</span>
                      </div>
                      
                      <div class="d-flex justify-content-between mt-2" v-for="(i, index) in fastSteps.step3.invoice.parcelas" :key="index"> 
                        <span class="fw-500">Parcela {{i.parcela}}</span> <span>Vencimento em {{ formataDataT(i.data_vencimento) }}</span> <span>R$ {{ formataPreco(i.valor_parcela) }}</span> 
                      </div>
                      <hr>
                    </div>
                  </div>
                </div>
                <div class="row mt-4 mb-4">
                  <div class="col-12 text-center">
                    <button class="btn btn-primary" @click="gerarFatura()">Confirmar cobrança</button>
                  </div>
                </div>
              </div>
              <div v-else class="card-body">
                <div class="row mt-2 mb-2">
                  <div class="col-sm-12 col-md-12 col-lg-12 text-center">
                    <h4 class="text-success">
                      Cobrança gerada com sucesso!
                    </h4>     
                    <h5 class="text-secondary">Em breve você receberá a fatura por email.</h5>
                  </div>
                </div>
              </div>
            </div>
          </tab-content>      
        </form-wizard>
        <!-- /CURSO LIVRE -->
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalDefinirPrimeiraParcela"
      :scrollable="true"
      height="auto"
      :shift-y="0.1"
      :focus-trap="true"
      :adaptive="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Defina a primeira parcela</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalDefinirPrimeiraParcela')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4 text-center">
          <div class="col-sm-12 col-md-12 col-lg-6">
            <input
              v-model="dataPrimeiraParcela"
              type="date"
              class="form-control"
              @change="definePrimeiraParcela()"
            >
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalErrosRequisicao"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Erros encontrados</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalErrosRequisicao')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12 table-responsive">  
              <table class="table table-sm table-striped">
                <thead class="thead-dark">
                  <tr>
                    <th>Nº</th>
                    <th>Descrição do erro</th>
                  </tr>
                </thead>
                <tbody v-if="fastErros.length">
                  <tr v-for="(erro, index) in fastErros" :key="index">
                    <td>{{index + 1}}</td>
                    <td>{{erro}}</td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="2">Erro desconhecido. Contate um administrador.</td>
                  </tr>
                </tbody>
              </table>
            </div>

          </div>
          <div class="row mt-4">
            <div class="col-12">
              <button class="btn btn-secondary mr-2" @click="hideModal('modalErrosRequisicao')">
                Fechar
              </button>
            </div>
          </div>
        </div>
      </div>
    </modal>

  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import headerCursoInfo from "@/assets/images/header-curso-info.jpg";
import { FormWizard, TabContent, WizardStep } from "vue-form-wizard"; //https://binarcode.github.io/vue-form-wizard/#/
import "vue-form-wizard/dist/vue-form-wizard.min.css";

export default {
  name: "LojaVirtualCarrinho",
  components: {
    FormWizard,
    TabContent,
    WizardStep,
  },
  mixins: [methods],
  data: function () {
    return {
      headerCursoInfo,
      //fastPlataforma
      usuarioLogado: false,
      fastPlataforma: {
        banner_plataforma: "",
        id_plataforma: 0,
        logo_plataforma: "",
        nome_plataforma: "",
        url_plataforma: "",
      },
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      modelCadastroUsuario: {
        fast_plataforma_usuario: {
          id_plataforma: this.$route.params.id_plataforma,
          administrador: "",
          edita_cursos: "",
          edita_usuarios: "",
          edita_professores: "",
          edita_plataforma: "",
          edita_matriz: "",
          edita_financeiro: "",
          edita_alunos: "",
          edita_admins: "",
          professor: "",
          aluno: "",
          tesouraria: "",
          secretario: "",
          responsavel: "",
          nome_usuario: ""
        },
        fast_usuario: {
          id_usuario: "",
          first_name: "",
          last_name: "",
          email: "",          
          password: "",
          password2: ""
        },
        fast_pessoa: {
          cpf_cnpj: "",
          datanascimento_abertura: null,
          banco: "",
          codigo_banco: "",
          sexo: "",
        },
        fast_pessoa_endereco: {
          tipo: "",
          uf: "",
          cep: "",
          logradouro: "",
          bairro: "",
          cidade: "",
          complemento: "",
          numero: ""
        },
        fast_pessoa_telefone: {
          tipo: 1,
          telefone: "",
          ramal: ""
        },
        fast_usuario_parametro: {
          image: ""
        },
        fast_plataforma_professor: {
          first_name: "",
          last_name: "",
          licenciatura: "",
          image: ""
        },
        fast_curso: {
          id_curso: 0
        },
        fast_secretaria_curso: {
          id_secretaria_curso: 0
        },
        envia_email: true,
        cadastrando: false,
        cadastro_finalizado: false,
        validacaoNome: {
          invalid: true,
          class: "text-secondary",          
          mensagem: "Insira seu nome completo"
        },
        validacaoEmail: {
          invalid: true,
          class: "text-secondary",          
          mensagem: "Insira seu email"
        },
        validacaoSenha: {
          invalid: true,
          class: "text-secondary",          
          mensagem: "Insira uma senha de no mínimo 6 caracteres"
        },
        validacaoCpf: {
          invalid: true,
          class: "text-secondary",          
          mensagem: "Insira seu CPF/CNPJ"
        },
        validacaoDataNascimento: {
          invalid: true,
          class: "text-secondary",          
          mensagem: "Insira a sua data de nascimento"
        },
        validacaoTelefone: {
          invalid: true,
          class: "text-secondary",          
          mensagem: "Insira seu telefone para contato"
        },
        ids_cursos: ""
        
      },
      modelLoginUsuario: {
        fast_usuario: {
          email: "",
          password: "",
          error: "",
          autenticando: false
        }
      },
      // Steps
      loadingWizard: true,
      errorMsg: null,
      count: 0,
      currentStep: 0,
      // Cursos
      fastSecretariaCursosTotal: [],
      fastSecretariaTurmasTotal: [],
      fastSecretariaModulosTotal: [],
      fastSecretariaUnidadesTotal: [],
      fastPlataformaAlunoResponsaveis: [],
      // Contrato
      fastSecretariaContratosCurso: [],
      fastContratoEscolhido: {
        id_contrato: "",
        html_template: ""
      },
      // Dados pessoais
      fastPessoa: {
        id_usuario: "",
        id_pessoa: 0,
        email: "",
        nome_razao: "",
        sobrenome_fantasia: "",
        first_name: "",
        last_name: "",
        cpf_cnpj: "",
        datanascimento_abertura: null,
        sexo: "",
        uf: "",
        cep: "",
        logradouro: "",
        bairro: "",
        cidade: "",
        complemento: "",
        numero: "",
        telefone: "",
        alteracao_pendente: false,
      },
      // Upload arquivo histórico
      optionsArquivo: {
        target: settings.endApiFastEad + "api/fast_plataforma_aluno_documento/upload",
        testChunks: false,
        chunkSize: 1024 * 1024 * 100, // 100MB
        singleFile: true,
        query: {
          id_plataforma: this.$route.params.id_plataforma,
          id_pessoa_aluno: this.$route.params.id_pessoa_aluno
        },
        method: "POST",
        headers: { Authorization: "Bearer " + this.retornaToken() },
        categoryMap: {
          document: ["pdf", "odt", "doc", "docx", "ppt", "pptx", "gif", "jpg", "jpeg", "png"],
        },
        parseTimeRemaining: function(timeRemaining, parsedTimeRemaining) {
          return parsedTimeRemaining
            .replace(/\syears?/, " anos")
            .replace(/\days?/, " dias")
            .replace(/\shours?/, " horas")
            .replace(/\sminutes?/, " minutos")
            .replace(/\sseconds?/, " segundos");
        },
      },
      restrictArquivo: {
        accept: "application/pdf,application/vnd.oasis.opendocument.text,application/msword,application/vnd.ms-powerpoint,image/*",
      },      
      statusText: {
        success: "Sucesso",
        error: "Erro",
        uploading: "Fazendo upload...",
        paused: "Upload pausado",
        waiting: "Aguardando...",
      },
      // Pagamento
      fastSecretariaPlanoPagamento: [],
      fastSecretariaTipoPagamento: [],
      dataPrimeiraParcela: "",
      // Steps
      fastSteps: {
        step1: {
          curso: {
            id_secretaria_curso: 0,
            carga_horaria: "",
            creditos_total: "",
            detalhe_curso: "",
            gera_certificado: "",
            id_plataforma: 0,
            logo_curso: "",
            nivel: "",
            nome_curso: "",
            preco: "",
            publicado: "",
            total_programas: "",
            venda_modulo: "",
            venda_unidade_curricular: "",
            possui_aproveitamento: ""
          },
          turma: {
            id_turma: 0,
            sigla_turma: "",
            turno: "",
            loading: true
          },
        },
        step2: {
          modulo: {
            lista: [],
            requisitos_obrigatorios: true,
            loading: true
          },
          unidade: {
            lista: [],
            requisitos_obrigatorios: true,
            loading: true
          },
          plano: "curso",
          itens: [],
          cursos_livres: [],
          total: 0.00
        },
        step3: {
          plano: {
            id_plano_pagamento: 0,
            nome_plano: "",
            obs: "",
            periocidade_cada_unidade: "",
            dia_mes: "",
            tempo_determinado: "",
            parcelas: [],
            loading: true
          },
          tipo: {
            id_tipo_pagamento: 0,
            nome_tipo_pagamento: "",
            forma_pagamento: "",
            loading: true
          },
          invoice: {
            id_plataforma: 0,
            id_invoice: "",
            data_registro: "",
            id_usuario_gerou: "",
            id_matricula: "",
            nome_aluno: "",
            nome_responsavel: "",
            cpf_responsavel: "",
            cpf_aluno: "",
            cep_responsavel: "",
            endereco_responsavel_numero: "",
            endereco_responsavel_complemento: "",
            subtotal: 0.00,
            desconto_total: 0.00,
            acrescimo: 0.00,
            total: 0.00,
            ativo: "",
            cancelado: "",
            motivo_cancelamento: "",
            id_usuario_cancelamento: "",
            data_cancelamento: "",
            obs: "",
            id_plano_pagamento: "",
            taxa_boleto: 0.00,
            taxa_cartao: 0.00,
            outras_taxas: 0.00,
            id_gateway: 0,
            id_pessoa_aluno: 0,
            tipo: "",
            parcelas: []
          },
          matricula: {
            id_matricula: "",
            id_pessoa_aluno: 0,
            tipo_matricula: "",
            data_matricula: null,
            RA: null,
            id_usuario_cadastro: "",
            ano_matricula: "",
            valor_matricula: 0.00,
            vencimento_matricula: null,
            url_boleto: null,
            pago: null,
            data_pagto: null,
            desconto_matricula: 0.00,
            outros_valores: 0.00,
            path_contrato: null,
            ativo: false,
            curso_definido: "",
            plano_pagto_definido: "",
            ajuste_definido: "",
            responsavel_definido: "",
            contrato_gerado: "",
            boleto_gerado: "",
            id_plataforma: 0,
            id_usuario_baixa_manual: "",
            log_pagto_auditoria: null,
            id_turma: "",
            matricula_por_programa: "",
            matricula_por_unidade: "",
            total: 0.00,
            nome_curso: "",
            sigla_turma: "",
            qtde_modulos_compra: 0,
            qtde_unidades_compra: 0,   
            id_invoice: 0         
          },
          pular_pagamento: false
        },
        step4: {
          id_pessoa_responsavel: 0,
          nome_responsavel: "",
          telefone_responsavel: "",
          aluno_responsavel: false,
          loading: true
        },
        step5: {
          path_contrato: "",
          loading: true
        },
        step6: {
          matricula: {
            matriculaInvoiceParcela: [],
            loading: true
          },
          invoice: {
            parcelas: [],
            loading: true
          }
        }
      },
      // Status
      statusGeracaoMatricula: {
        iniciou: false,
        definicao_curso: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        definicao_turma: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        definicao_plano: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },        
        definicao_plano_pagamento: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        definicao_tipo_pagamento: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        definicao_responsavel: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        matricula_gerada: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        insercao_modulos: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        insercao_unidades: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        insercao_invoice: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
        insercao_parcelas: {
          status: "waiting",
          message: "Aguardando confirmação..."
        },
      },
      // pdf
      htmlToPdfOptions: {
        margin: 0.5,
        filename: 'Contrato.pdf',
        image: {
            type: 'jpeg',
            quality: 1
        },
        enableLinks: false,
        html2canvas: {
            scale: 1,
            scrollX: 0,
            scrollY: 0,
            useCORS: true,
            width: 960,
        },
        jsPDF: {
            unit: 'in',
            format: 'a4',
            orientation: 'portrait'
        },
        //pagebreak: { mode: ['avoid-all', 'css', 'legacy'] }
      },
      // Erros
      fastErros: [],
      // WhatsApp
      fastLinkWhats: {
        path_boleto: "",
        parcela: "",
        valor_parcela_total: "",
        data_vencimento: "",
        nome_plataforma: "",
        tipo: "",
        telefone_aluno: "",
        telefone_responsavel: ""
      },            
    };
  },
  mounted() {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.$store.state.fastPermissoes.id_plataforma = this.$route.params.id_plataforma
      // if (this.getUrlParameter("course_code")) {
      // } else if (getUrlParameter("promo_code")) {
      // } else {

      if (this.validaSessao()) {
        setTimeout(() => {
          this.validaToken(settings.endFastEad, settings.endApiFastEad);
        }, 5000);

        this.usuarioLogado = true;
        this.buscaInfoPessoa(this.$route.params.id_plataforma)                  
        
      } else {
        this.usuarioLogado = false;
      }
     
      this.getPlataformaData();
      
      
      // }
    }
  },
  computed: {
    
  },
  methods: {
    async getPlataformaData() {
      try {
        let resp = await fetch(
          settings.endApiFastEad +
            "api/fast_plataforma/lista_detalhes_cliente?id_plataforma=" +
            this.$route.params.id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();
        let obj = Array.from(json);
        if (obj.length) {
          this.fastPlataforma = obj[0];
        }

        if (this.getUrlParameter('id_secretaria_curso')) {
          this.promiseGetFastApi("api/fast_secretaria_curso/lista_loja_curso_detalhes", "id_plataforma=" + this.$route.params.id_plataforma + "&id_secretaria_curso=" + parseInt(this.getUrlParameter('id_secretaria_curso')))
          .then(res => {
            console.log("lista_loja_curso_detalhes", res)
          if (res.length) {
            this.fastSteps.step1.curso = res[0]
            this.fastSteps.step1.curso.id_secretaria_curso = res[0].id_secretaria_curso
            this.getSecretariaTurmas(this.$route.params.id_plataforma)
            if (this.getUrlParameter('secretaria')) this.exibeToasty("Faça login para continuar o processo", "success")
          }
          else {    
            this.exibeToasty("Erro ao buscar informações do curso", "error")      
          }
        })
        } else if (this.getUrlParameter('ids_cursos')) {
          this.$store.state.fastCarrinho = []
          let ids_cursos = atob(this.getUrlParameter('ids_cursos'))
          console.log("this.getUrlParameter('ids_cursos')", atob(this.getUrlParameter('ids_cursos')))
          this.promiseGetFastApi("api/fast_curso_plataforma/lista_cursos_detalhes", "id_plataforma=" + this.$route.params.id_plataforma + "&ids_cursos=" + ids_cursos)
          .then(res => {
            console.log("api/fast_curso_plataforma/lista_cursos_detalhes", res)
            if (res.length) {
              this.$store.state.fastCarrinho = res
              this.fastSteps.step2.total = 0.00       
              this.$store.state.fastCarrinho.forEach((c) => {
                this.fastSteps.step2.total += c.valor_item
                this.fastSteps.step2.cursos_livres.push(c)
              }) 
            }
            else { 
              this.exibeToasty("Erro ao buscar informações do curso", "error")         
            }
          })
        } else {
          // Verifica se os produtos possuem preço
          if (this.$store.state.fastCarrinho.length) {   
            this.fastSteps.step2.total = 0.00       
            this.$store.state.fastCarrinho.forEach((c) => {
              this.fastSteps.step2.total += c.valor_item
              this.fastSteps.step2.cursos_livres.push(c)
            })     
          } else {
            this.exibeToasty("Nenhum produto encontrado no carrinho", "error")
            window.location.href = '/loja-virtual/' + this.$route.params.id_plataforma
          }
        }

        
        
        // Informa ao component pai para interromper o loading
        this.$store.state.fastCarregando = false;

      } catch (e) {
        console.log("Erro", e);
      }
    },
    async buscaInfoPessoa(id_plataforma) {
      try {
        let resp = await fetch(
          settings.endApiFastEad + "api/fast_plataforma_usuario/busca_pessoa_plataforma?id_plataforma=" + id_plataforma,
          this.fastAjaxOptions("GET")
        );
        let json = await resp.json();        
        let obj = JSON.parse(json);
        if (!obj.error) {
          if (obj.datanascimento_abertura)
            obj.datanascimento_abertura = obj.datanascimento_abertura.split(
              "T"
            )[0];
          else obj.datanascimento_abertura = "";
          if (!obj.first_name) obj.first_name = "";
          if (!obj.last_name) obj.last_name = "";
          if (!obj.cpf_cnpj) obj.cpf_cnpj = "";
          if (!obj.cep) obj.cep = "";
          if (!obj.cidade) obj.cidade = "";
          if (!obj.complemento) obj.complemento = "";
          if (!obj.logradouro) obj.logradouro = "";
          if (!obj.numero) obj.numero = "";
          if (!obj.sexo) obj.sexo = "";
          if (!obj.telefone) obj.telefone = "";
          if (!obj.uf) obj.uf = "";
          if (!obj.bairro) obj.bairro = "";

          this.fastPessoa = obj;

          if (!this.fastPessoa.id_usuario) this.exibeToasty("Não foi possível identificar o Id_usuario. Consulte um administrador do sistema.", "error");
          if (!this.fastPessoa.id_pessoa) this.exibeToasty("Não foi possível identificar o Id_pessoa. Consulte um administrador do sistema.", "error");
          if (!this.fastPessoa.email) this.exibeToasty("Não foi possível identificar o email do usuário. Consulte um administrador do sistema.", "error");
          if (!this.fastPessoa.telefone) this.exibeToasty("Não foi possível identificar o telefone do usuário. Consulte um administrador do sistema.", "error");
        }
        console.log(this.fastPessoa);
        this.$store.state.fastCarregando = false;
      } catch (e) {
        console.log("Erro", e);
      }
    },
    verificaCadastrarUsuarioCompleto(){
      let erros = []
      if (!this.modelCadastroUsuario.fast_usuario.first_name) erros.push("O nome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.last_name) erros.push("O sobrenome é obrigatório")
      if (!this.modelCadastroUsuario.fast_usuario.email) erros.push("O email é obrigatório")

      this.modelCadastroUsuario.fast_plataforma_usuario.administrador = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.edita_cursos = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.edita_usuarios = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.edita_professores = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.edita_plataforma = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.edita_matriz = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.edita_financeiro = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.edita_alunos = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.edita_admins = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.professor = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.aluno = "S"
      this.modelCadastroUsuario.fast_plataforma_usuario.tesouraria = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.secretario = "N"
      this.modelCadastroUsuario.fast_plataforma_usuario.responsavel = "N"

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
      } else {
        this.fastEmailCadastro.btnCadastrar = false

        this.fastEmailCadastro.status = "Verificando email na base..."
        this.promiseGetFastApi("api/fast_plataforma_usuario/usuario_check", `email=${this.modelCadastroUsuario.fast_usuario.email}&id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          const retorno = JSON.parse(res)
          if (retorno.error){
            this.exibeToasty(retorno.description, "error")
          } else {
            this.fastEmailCadastro.code = retorno.code

            switch (retorno.code) {
              case "email_on_plataform":
                this.fastEmailCadastro.status = "Não é possível convidar pois o usuário já está associado à plataforma"              
                this.fastEmailCadastro.classe = "text-danger"
                this.fastEmailCadastro.first_name = retorno.first_name
                this.fastEmailCadastro.last_name = retorno.last_name
                this.fastEmailCadastro.image = retorno.image
                this.fastEmailCadastro.id_usuario = retorno.id_usuario
                this.fastEmailCadastro.id_pessoa = retorno.id_pessoa              
                this.fastEmailCadastro.email = retorno.email
                this.fastEmailCadastro.administrador = retorno.administrador
                this.fastEmailCadastro.professor = retorno.professor
                this.fastEmailCadastro.aluno = retorno.aluno
                this.fastEmailCadastro.tesouraria = retorno.tesouraria
                this.fastEmailCadastro.secretario = retorno.secretario
                this.fastEmailCadastro.responsavel = retorno.responsavel
                break;
              case "email_on_system":
                this.fastEmailCadastro.status = "Email já cadastrado no sistema. Confirme o convite"
                this.fastEmailCadastro.classe = "text-warning"
                this.fastEmailCadastro.first_name = retorno.first_name
                this.fastEmailCadastro.last_name = retorno.last_name
                this.fastEmailCadastro.image = retorno.image
                this.fastEmailCadastro.id_usuario = retorno.id_usuario
                this.fastEmailCadastro.email = retorno.email
                break;
              case "email_unknown":
                this.fastEmailCadastro.status = "Cadastrando novo usuário. Aguarde..."
                this.fastEmailCadastro.classe = "text-success"
                this.cadastrarUsuarioCompleto()
                break;          
              default:
                e.status = "Código de retorno não identificado"
                e.classe = "text-danger"
                break;
            }   
          }  

        }).catch(e => {         
          e.status = "Erro ao verificar email"
          e.classe = "text-danger"
        })
        
      }
    },
    validaNome(){
      if (this.modelCadastroUsuario.fast_plataforma_usuario.nome_usuario.length) {
        if (this.modelCadastroUsuario.fast_plataforma_usuario.nome_usuario.trim().split(" ").length > 1) {
          this.modelCadastroUsuario.validacaoNome.invalid = false
          this.modelCadastroUsuario.validacaoNome.mensagem = "Nome válido"
          this.modelCadastroUsuario.validacaoNome.class = "text-success"
        } else {
          this.modelCadastroUsuario.validacaoNome.invalid = true
          this.modelCadastroUsuario.validacaoNome.mensagem = "Insira seu nome completo"
          this.modelCadastroUsuario.validacaoNome.class = "text-danger"
        }
      } else {
        this.modelCadastroUsuario.validacaoNome.invalid = true
        this.modelCadastroUsuario.validacaoNome.mensagem = "O nome é obrigatório"
        this.modelCadastroUsuario.validacaoNome.class = "text-danger"
      }      
    },
    validaEmail() {
      if (!this.validateEmail(this.modelCadastroUsuario.fast_usuario.email)) {
        this.modelCadastroUsuario.validacaoEmail.invalid = true
        this.modelCadastroUsuario.validacaoEmail.mensagem = "Email inválido"
        this.modelCadastroUsuario.validacaoEmail.class = "text-danger"
      } else {
        this.validaEmailDisponibilidade(this.modelCadastroUsuario.fast_usuario.email)
      }
    },
    validaSenha(){
      if (this.modelCadastroUsuario.fast_usuario.password) {
        if (this.modelCadastroUsuario.fast_usuario.password.length > 5) {
          if (this.modelCadastroUsuario.fast_usuario.password == this.modelCadastroUsuario.fast_usuario.password2) {
            this.modelCadastroUsuario.validacaoSenha.invalid = false
            this.modelCadastroUsuario.validacaoSenha.mensagem = "Senha válida"
            this.modelCadastroUsuario.validacaoSenha.class = "text-success"
          } else {
            this.modelCadastroUsuario.validacaoSenha.invalid = true
            this.modelCadastroUsuario.validacaoSenha.mensagem = "As senhas não coincidem"
            this.modelCadastroUsuario.validacaoSenha.class = "text-danger"
          }
        } else {
          this.modelCadastroUsuario.validacaoSenha.invalid = true
          this.modelCadastroUsuario.validacaoSenha.mensagem = "A senha deve possuir ao menos 6 caracteres"
          this.modelCadastroUsuario.validacaoSenha.class = "text-danger"
        }        
      } else {
        this.modelCadastroUsuario.validacaoSenha.invalid = true
        this.modelCadastroUsuario.validacaoSenha.mensagem = "O campo senha é obrigatório"
        this.modelCadastroUsuario.validacaoSenha.class = "text-danger"
      } 
    },
    validaCpf(){
      if (this.modelCadastroUsuario.fast_pessoa.cpf_cnpj) {
        if (this.validaCpfCnpj(this.modelCadastroUsuario.fast_pessoa.cpf_cnpj)) {
          this.modelCadastroUsuario.validacaoCpf.invalid = false
          this.modelCadastroUsuario.validacaoCpf.mensagem = "CPF/CNPJ válido"
          this.modelCadastroUsuario.validacaoCpf.class = "text-success"
        } else {
          this.modelCadastroUsuario.validacaoCpf.invalid = true
          this.modelCadastroUsuario.validacaoCpf.mensagem = "CPF/CNPJ inválido"
          this.modelCadastroUsuario.validacaoCpf.class = "text-danger"
        }
      } else {
        this.modelCadastroUsuario.validacaoCpf.invalid = true
        this.modelCadastroUsuario.validacaoCpf.mensagem = "O campo CPF/CNPJ é obrigatório"
        this.modelCadastroUsuario.validacaoCpf.class = "text-danger"
      }      
    },
    async validaEmailDisponibilidade(email){
      this.promiseGetFastApi("api/fast_plataforma_usuario/email_check", `email=${email}&id_plataforma=${this.$route.params.id_plataforma}`).then(res => {
          const retorno = JSON.parse(res)
          if (retorno.error){
            this.modelCadastroUsuario.validacaoEmail.invalid = true
            this.modelCadastroUsuario.validacaoEmail.mensagem = retorno.description
            this.modelCadastroUsuario.validacaoEmail.class = "text-danger"
          } else {
            switch (retorno.code) {
              case "email_on_plataform":           
                this.modelCadastroUsuario.validacaoEmail.invalid = true
                this.modelCadastroUsuario.validacaoEmail.mensagem = "Email já associado à plataforma." 
                this.modelCadastroUsuario.validacaoEmail.class = "text-danger"
                break;
              case "email_on_system":
                this.modelCadastroUsuario.validacaoEmail.invalid = true
                this.modelCadastroUsuario.validacaoEmail.mensagem = "Email já cadastrado no sistema."
                this.modelCadastroUsuario.validacaoEmail.class = "text-danger"
                break;
              case "email_unknown":
                this.modelCadastroUsuario.validacaoEmail.invalid = false
                this.modelCadastroUsuario.validacaoEmail.mensagem = "Email válido"
                this.modelCadastroUsuario.validacaoEmail.class = "text-success"
                break;          
              default:
                this.modelCadastroUsuario.validacaoEmail.invalid = true
                this.modelCadastroUsuario.validacaoEmail.mensagem = "Erro desconhecido."
                this.modelCadastroUsuario.validacaoEmail.class = "text-danger"
                break;
            }   
          }
        }).catch(e => {    
          this.modelCadastroUsuario.validacaoEmail.invalid = true
          this.modelCadastroUsuario.validacaoEmail.mensagem = "Erro ao verificar email"
          this.modelCadastroUsuario.validacaoEmail.class = "text-danger"
        })
    },
    validaDataNascimento(){
      this.getFastHoraServidor()
      .then(() => {
        if (this.modelCadastroUsuario.fast_pessoa.datanascimento_abertura) {
          let objDataNasc = new Date(`${this.modelCadastroUsuario.fast_pessoa.datanascimento_abertura}T00:00:00`)
          let dezoitoAnosAtras = new Date(this.$store.state.fastDataAtualServidor.setFullYear(this.$store.state.fastDataAtualServidor.getFullYear() - 18))
          if (objDataNasc > dezoitoAnosAtras) {
            this.modelCadastroUsuario.validacaoDataNascimento.invalid = true
            this.modelCadastroUsuario.validacaoDataNascimento.mensagem = "Você deve possuir ao menos 18 anos de idade"
            this.modelCadastroUsuario.validacaoDataNascimento.class = "text-danger"
          } else {
            this.modelCadastroUsuario.validacaoDataNascimento.invalid = false
            this.modelCadastroUsuario.validacaoDataNascimento.mensagem = "Data de nascimento válida"
            this.modelCadastroUsuario.validacaoDataNascimento.class = "text-success"
          }
        } else {
          this.modelCadastroUsuario.validacaoDataNascimento.invalid = true
          this.modelCadastroUsuario.validacaoDataNascimento.mensagem = "A data de nascimento é obrigatória"
          this.modelCadastroUsuario.validacaoDataNascimento.class = "text-danger"
        }
      })
      .catch(e => {
        this.exibeToasty(e, "error")
        this.modelCadastroUsuario.validacaoDataNascimento.invalid = true
        this.modelCadastroUsuario.validacaoDataNascimento.mensagem = e
        this.modelCadastroUsuario.validacaoDataNascimento.class = "text-danger"
      })
    },
    validaTelefone(){
      if (this.modelCadastroUsuario.fast_pessoa_telefone.telefone) {
        if (this.validaTelefoneFormatado(this.modelCadastroUsuario.fast_pessoa_telefone.telefone)) {
          this.modelCadastroUsuario.validacaoTelefone.invalid = false
          this.modelCadastroUsuario.validacaoTelefone.mensagem = "Telefone válido"
          this.modelCadastroUsuario.validacaoTelefone.class = "text-success"
        } else {
          this.modelCadastroUsuario.validacaoTelefone.invalid = true
          this.modelCadastroUsuario.validacaoTelefone.mensagem = "Telefone inválido"
          this.modelCadastroUsuario.validacaoTelefone.class = "text-danger"
        }
      } else {
        this.modelCadastroUsuario.validacaoTelefone.invalid = true
        this.modelCadastroUsuario.validacaoTelefone.mensagem = "O telefone é obrigatório"
        this.modelCadastroUsuario.validacaoTelefone.class = "text-danger"
      }      
    },
    validaTodosCampos(){      
      let validado = true
      if (this.modelCadastroUsuario.validacaoEmail.invalid) validado = false
      if (this.modelCadastroUsuario.validacaoSenha.invalid) validado = false
      if (this.modelCadastroUsuario.validacaoCpf.invalid) validado = false
      if (this.modelCadastroUsuario.validacaoDataNascimento.invalid) validado = false
      if (this.modelCadastroUsuario.validacaoTelefone.invalid) validado = false
      return validado
    },
    cadastrarUsuarioCompleto(){
      this.modelCadastroUsuario.cadastrando = true
      this.hideNextButton();
      
      this.modelCadastroUsuario.fast_plataforma_usuario.id_plataforma = this.$route.params.id_plataforma
      this.modelCadastroUsuario.fast_usuario.password = btoa(this.modelCadastroUsuario.fast_usuario.password)
      
      this.modelCadastroUsuario.fast_usuario.first_name = this.modelCadastroUsuario.fast_plataforma_usuario.nome_usuario.split(" ")[0]
      let last_name = []
      
      this.modelCadastroUsuario.fast_plataforma_usuario.nome_usuario.split(" ").forEach((e, index) => {
        if (index) last_name.push(e)
      })
      this.modelCadastroUsuario.fast_usuario.last_name = last_name.join(" ")

      this.modelCadastroUsuario.fast_pessoa.nome_razao = this.modelCadastroUsuario.fast_usuario.first_name
      this.modelCadastroUsuario.fast_pessoa.sobrenome_fantasia = this.modelCadastroUsuario.fast_usuario.last_name

      if (this.getUrlParameter('secretaria')) {
        this.modelCadastroUsuario.fast_secretaria_curso.id_secretaria_curso = this.$store.state.fastCarrinho[0].id_secretaria_curso
      } else {
        let ids_cursos = []        
        this.$store.state.fastCarrinho.forEach((c) => ids_cursos.push(c.id_curso)) 
        this.modelCadastroUsuario.ids_cursos = btoa(ids_cursos.join(","))
      }
      
      
      this.promisePostFastApi(this.modelCadastroUsuario, "api/fast_plataforma_usuario/cadastro_usuario_loja").then(res => {
        const retorno = JSON.parse(res)
        if (retorno.error) {
          this.exibeToasty(e.description, "error");
          this.modelCadastroUsuario.cadastrando = false
        } else {
          if (retorno.code == "OK") {
            this.exibeToasty("Usuário cadastrado com sucesso", "success");
            this.modelCadastroUsuario.cadastro_finalizado = true
          }
        }
      }).catch(e => {         
        this.exibeToasty("Erro ao cadastrar usuário", "error");
        this.modelCadastroUsuario.cadastrando = false
      })
      
    },
    validaTodosCamposLogin(){
      
      if (this.modelLoginUsuario.fast_usuario.password.length > 5 && this.validateEmail(this.modelLoginUsuario.fast_usuario.email)) {
        this.modelLoginUsuario.fast_usuario.error = ""
        return true
      }
      else {
          return false
        }
    },
    async fastAutenticar(e) {
      e.preventDefault();

      this.modelLoginUsuario.fast_usuario.autenticando = true
      this.modelLoginUsuario.fast_usuario.error = ""
      this.modelLoginUsuario.fast_usuario.password = btoa(this.modelLoginUsuario.fast_usuario.password)

      let options = {
        headers: {
          "Content-Type": "application/json; charset=utf-8",
        },
        method: "POST",
        body: `username=${this.modelLoginUsuario.fast_usuario.email}&password=${this.modelLoginUsuario.fast_usuario.password}&grant_type=password`,
      };
      fetch(`${settings.endApiFastEad}api/token`, options)
        .then((res) => res.json())
        .then((res) => {
          if (res.access_token) {
            this.setFastSessao(settings.fastSessaoToken, res)
            return this.promiseGetUsuario();            
          } else {
            this.modelLoginUsuario.fast_usuario.autenticando = false
            this.modelLoginUsuario.fast_usuario.error = res.error_description
            this.modelLoginUsuario.fast_usuario.password = ""
            return res;
          }
        })
        .then((res) => { 
          if (!res.error) {
            this.setFastSessao(settings.fastSessaoUsuario, res)
            return true
          } else {
            this.modelLoginUsuario.fast_usuario.autenticando = false;
            this.modelLoginUsuario.fast_usuario.error = res.error_description;
            return false
          }          
        })
        .then(res => {
          if (res) {
            //this.usuarioLogado = true;
            //this.buscaInfoPessoa(this.$route.params.id_plataforma)
            location.reload();
          }
        })
        .catch((e) => {
          this.modelLoginUsuario.fast_usuario.autenticando = false;
          this.modelLoginUsuario.fast_usuario.error = e;
        });
      
    },
    promiseGetUsuario() {
      return new Promise(async (resolve, reject) => {
        this.promiseGetFastApi(
          "api/fast_plataforma_usuario/busca_usuario",
          ""
        )
          .then((obj) => {
            resolve(JSON.parse(obj));
          })
          .catch((e) => {
            reject(e);
          });
      });
    },
    validaIdentificacao(){
      return new Promise(async (resolve, reject) => {
          if (this.usuarioLogado) {    
            if (!this.getUrlParameter('id_secretaria_curso')) {
              if (this.fastSteps.step2.total > 5) {
                // Buscando as formas de pagamento
                this.getSecretariaPlanoPagamento(this.$route.params.id_plataforma);
                this.getSecretariaTipoPagamento(this.$route.params.id_plataforma);
                resolve(true);
                return true;
              } else {
                this.exibeToasty("Não foi possível identificar o valor total", "error");
                reject(true)
                return false
              }
              
            }      
              
            } else {
              this.exibeToasty("Você deve estar autenticado", "error");
              reject(true)
              return false
            }      
                
      });
    },
    // Primeiro passo
    getSecretariaTurmas(id_plataforma) {
      this.fastSteps.step1.turma.id_turma = 0
      if (this.fastSteps.step1.curso.id_secretaria_curso) {

        this.fastSteps.step1.turma.loading = true;
        this.promiseGetFastApi("api/fast_secretaria_turma/lista", "id_plataforma=" + id_plataforma + "&id_turma=0").then(json => {
          let obj = Array.from(json);  
          console.log("getSecretariaTurmas", obj)
          if (obj.length) {  
            this.fastSecretariaTurmasTotal = obj.filter(e =>  e.id_secretaria_curso === this.fastSteps.step1.curso.id_secretaria_curso)           
          } else {
            this.fastSecretariaTurmasTotal = [];
          }
          this.fastSteps.step1.turma.loading = false;
        }).catch(e => {
          console.log(e);
        })

        
      } else {
        this.fastSteps.step1.modulo.id_turma = 0;
        this.fastSteps.step1.modulo.id_programa_turma = 0;
        this.fastSteps.step1.turma.loading = false;
      }
    },
    async validaPrimeiroPasso() {
      
      return new Promise(async (resolve, reject) => {
        console.log("this.fastSteps.step1.turma.id_turma", this.fastSteps.step1.turma.id_turma)
          if (this.fastSteps.step1.curso.id_secretaria_curso && this.fastSteps.step1.turma.id_turma > 0) {   
            if (this.fastSteps.step1.curso.preco) {
              this.getSecretariaModulos(this.fastSteps.step1.turma.id_turma)
              this.fastSecretariaTurmasTotal.forEach(t => {
                if (t.id_turma == this.fastSteps.step1.turma.id_turma) {
                  this.fastSteps.step1.turma.sigla_turma = t.sigla_turma

                  if (t.turno == 1) this.fastSteps.step1.turma.turno = "Manhã"
                  else if (t.turno == 1) this.fastSteps.step1.turma.turno = "Tarde"
                  else if (t.turno == 1) this.fastSteps.step1.turma.turno = "Noite"
                  else this.fastSteps.step1.turma.turno = "Sem turno"
                }
              })
              resolve(true);
              return true;
            } else {
              this.exibeToasty("O curso não possui preço definido", "error");
              reject(true)
              return false
            }       
          } else {
            this.exibeToasty("Escolha um curso e uma turma", "error");
            reject(true)
            return false
          }          
      });
    },
    // Segundo passo
    getSecretariaModulos(id_turma){
      this.fastSteps.step2.modulo.loading = true;
      this.promiseGetFastApi("api/fast_secretaria_turma_programa/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=" + id_turma).then(json => {        
        let obj = Array.from(json);        
        console.log("getSecretariaModulos", obj)
        if (obj.length) {  
          obj.forEach(u => {
            u.unidades_curriculares = []
            if (u.data_inicio) u.data_inicio = u.data_inicio.split("T")[0]
          })
          this.fastSecretariaModulosTotal = obj;

          // Busca unidades curriculares
          this.getSecretariaUnidadesCurriculares();
        } else {
          this.fastSecretariaModulosTotal = [];
        }        
        this.fastSteps.step2.modulo.loading = false;
      }).catch(e => {
        console.log(e);
      })
    },
    getSecretariaUnidadesCurriculares() {
        this.fastSteps.step2.unidade.loading = true;
        this.promiseGetFastApi("api/fast_secretaria_turma_programa_unidade/lista_todas", "id_plataforma=" + this.$route.params.id_plataforma + "&id_turma=" + this.fastSteps.step1.turma.id_turma).then(json => {
          let obj = Array.from(json);  
          console.log("getSecretariaUnidadesCurriculares", obj)
          if (obj.length) {              
            obj.forEach(u => {
              this.fastSecretariaModulosTotal.forEach(m => {
                if (m.id_secretaria_curso_programa == u.id_secretaria_curso_programa) m.unidades_curriculares.push(u)
              })
              if (u.data_inicio) u.data_inicio = u.data_inicio.split("T")[0]
            })            
            this.fastSecretariaUnidadesTotal = obj         
          } else {
            this.fastSecretariaUnidadesTotal = [];
          }
          this.fastSteps.step2.unidade.loading = false;

          //console.log("fastSecretariaModulosTotal", this.fastSecretariaModulosTotal)
        }).catch(e => {
          console.log(e);
        })
      
    },
    selecionaModulo(modulo) {
      // Se módulo já está incluído na lista
      if (this.fastSteps.step2.modulo.lista.includes(modulo.id_secretaria_curso_programa)) {
        if (!this.fastSteps.step2.modulo.requisitos_obrigatorios) {
          // Remove módulo da lista
          this.fastSteps.step2.modulo.lista = this.fastSteps.step2.modulo.lista.filter(function(item) {
              return item !== modulo.id_secretaria_curso_programa
          })
        } else {
          // Remove todos os módulos que possuem esse módulo como pré-requisito
          this.validaRemoverModulo(modulo)
        }        
      } else {
        // Se tem pré-requisito
        if (modulo.id_pai && this.fastSteps.step2.modulo.requisitos_obrigatorios) {
          this.validaAdicionarModulo(modulo)
        } 
        // Adiciona módulo da lista
        this.fastSteps.step2.modulo.lista.push(modulo.id_secretaria_curso_programa)  
      }

      if (this.fastSteps.step2.modulo.lista.length == this.fastSecretariaModulosTotal.length) this.exibeToasty("Você selecionou todos os módulos do curso!", "info");
    },
    validaAdicionarModulo(modulo) {
      // Se o pré-requisito não está na lista adiciona
      if (!this.fastSteps.step2.modulo.lista.includes(modulo.id_pai)) {
        this.fastSteps.step2.modulo.lista.push(modulo.id_pai)

        // Adiciona unidades curriculares
        this.fastSecretariaUnidadesTotal.forEach(t => {
          if (t.id_secretaria_curso_programa == modulo.id_pai && !this.fastSteps.step2.unidade.lista.includes(t.id_secretaria_curso_programa_unidade)) {
            this.fastSteps.step2.unidade.lista.push(t.id_secretaria_curso_programa_unidade)
          }
        });

        // Verifica se modulo atual possui pai
        this.fastSecretariaModulosTotal.forEach(t => {
          if (t.id_secretaria_curso_programa == modulo.id_pai) {
            if (t.id_pai) {
              this.validaAdicionarModulo(t)
            }
          }
        });
      }
    },
    validaRemoverModulo(modulo) {
      this.fastSteps.step2.modulo.lista.forEach(m => {
        this.fastSecretariaModulosTotal.forEach(t => {
          if (m == t.id_secretaria_curso_programa) {
            if (t.id_pai == modulo.id_secretaria_curso_programa) {               
              this.validaRemoverModulo(t)
            } else {
              // Remove módulo da lista
              this.fastSteps.step2.modulo.lista = this.fastSteps.step2.modulo.lista.filter(function(item) {
                  return item !== modulo.id_secretaria_curso_programa
              })
            } 
          }
        })          
      })
    },
    calculaPrecoTotal(array, tipo) {
      
      let valorTotal = 0.00;
      if (tipo == 'modulo') {
        array.forEach(id_secretaria_curso_programa => {
          this.fastSecretariaModulosTotal.forEach(t => {
            if (t.id_secretaria_curso_programa == id_secretaria_curso_programa) {
              if (t.preco) {
                valorTotal += t.preco
              }              
            }
          });          
        })
      } else {
        array.forEach(id_secretaria_curso_programa_unidade => {
          this.fastSecretariaUnidadesTotal.forEach(t => {
            if (t.id_secretaria_curso_programa_unidade == id_secretaria_curso_programa_unidade) {
              if (t.preco) {
                valorTotal += t.preco
              }
            }
          });
          
        })
      }
      
      return valorTotal
    },
    selecionaUnidade(unidade) {
      // Se módulo já está incluído na lista
      if (this.fastSteps.step2.unidade.lista.includes(unidade.id_secretaria_curso_programa_unidade)) {
        if (!this.fastSteps.step2.unidade.requisitos_obrigatorios) {
          // Remove módulo da lista
          this.fastSteps.step2.unidade.lista = this.fastSteps.step2.unidade.lista.filter(function(item) {
              return item !== unidade.id_secretaria_curso_programa_unidade
          })
        } else {
          // Remove todos os módulos que possuem esse módulo como pré-requisito
          this.validaRemoverUnidadeCurricular(unidade)
        }        
      } else {
        // Se tem pré-requisito
        if (unidade.id_pai && this.fastSteps.step2.unidade.requisitos_obrigatorios) {
          this.validaAdicionarUnidadeCurricular(unidade)
        } 
        // Adiciona módulo da lista
        this.fastSteps.step2.unidade.lista.push(unidade.id_secretaria_curso_programa_unidade)  
      }

      if (this.fastSteps.step2.unidade.lista.length == this.fastSecretariaUnidadesTotal.length) {
        this.exibeToasty("Você selecionou todas as unidades dos módulos!", "info");
      }
    },
    validaAdicionarUnidadeCurricular(unidade) {
      // Verifica requisitos do módulo
      this.fastSecretariaModulosTotal.forEach(t => {
        if (t.id_secretaria_curso_programa == unidade.id_secretaria_curso_programa) {
          if (t.id_pai) {
            this.validaAdicionarModulo(t)
          }
        }
      });

      // Se o pré-requisito não está na lista adiciona
      if (!this.fastSteps.step2.unidade.lista.includes(unidade.id_pai)) {
        this.fastSteps.step2.unidade.lista.push(unidade.id_pai)
        this.fastSecretariaUnidadesTotal.forEach(t => {
          if (t.id_secretaria_curso_programa_unidade == unidade.id_pai) {
            if (t.id_pai) {
              this.validaAdicionarUnidadeCurricular(t)
            }
          }
        });
      }

    },
    validaRemoverUnidadeCurricular(unidade) {
      this.fastSteps.step2.unidade.lista.forEach(m => {
        this.fastSecretariaUnidadesTotal.forEach(t => {
          if (m == t.id_secretaria_curso_programa_unidade) {
            if (t.id_pai == unidade.id_secretaria_curso_programa_unidade) {               
              this.validaRemoverUnidadeCurricular(t)
            } else {
              // Remove unidade da lista
              this.fastSteps.step2.unidade.lista = this.fastSteps.step2.unidade.lista.filter(function(item) {
                  return item !== unidade.id_secretaria_curso_programa_unidade
              })
            } 
          }
        })          
      })
    },
    async validaSegundoPasso() {
      return new Promise(async (resolve, reject) => {   
        let erros = []
        if (!this.fastPessoa.id_pessoa) erros.push("Pessoa não identificada")
        if (!this.fastSteps.step1.turma.id_turma) erros.push("Turma não identificada")

        if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          reject(true)
          return false
        } else {
          let fast_secretaria_matricula_aluno_solicitacao = {
            id_pessoa_aluno: this.fastPessoa.id_pessoa,
            id_plataforma: this.$route.params.id_plataforma,
            id_turma: this.fastSteps.step1.turma.id_turma,
            matricula_por_programa: false,
            matricula_por_unidade: false,
            path_inscricao: "",
            modulos: [],
            unidades: []
          }
          switch (this.fastSteps.step2.plano) {
            case "curso":       
              this.enviaSolicitacaoMatricula(fast_secretaria_matricula_aluno_solicitacao)
              .then(res => {            
                resolve(true);
                return true;
              })
              .catch(e => {
                this.exibeToasty(e, "error")
                reject(true)
                return false
              })         
              break;
            case "modulo":
              fast_secretaria_matricula_aluno_solicitacao.matricula_por_programa = true
              if (this.fastSteps.step2.modulo.lista.length) {
                this.enviaSolicitacaoMatricula(fast_secretaria_matricula_aluno_solicitacao)
                .then(res => {
                  this.fastSteps.step2.modulo.lista.forEach((m, index) => {            
                      this.fastSecretariaModulosTotal.forEach(t => {
                        if (m == t.id_secretaria_curso_programa) {
                          let obj = {            
                            id_matricula_solicitacao: res[0].id_matricula_solicitacao,
                            id_programa_turma: t.id_programa_turma
                          }             
                          this.promisePostFastApi(obj, "api/fast_secretaria_matricula_aluno_programa_solicitacao/insere").then(res => {
                            if ((index + 1) == this.fastSteps.step2.modulo.lista.length) {
                              resolve(true);
                              return true;
                            }                 
                          }).catch((e) => {
                            this.exibeToasty(e, "error")
                            reject(true)
                            return false
                          })
                        }
                      })
                  })

                })                
                .catch(e => {
                  this.exibeToasty(e, "error")
                  reject(true)
                  return false
                })   
              } else {
                this.exibeToasty("Escolha um módulo", "error");
                reject(true)
                return false
              }
              break;
            case "unidade":
              fast_secretaria_matricula_aluno_solicitacao.matricula_por_unidade = true
              if (this.fastSteps.step2.unidade.lista.length) {              
                this.enviaSolicitacaoMatricula(fast_secretaria_matricula_aluno_solicitacao)
                .then(res => {
                  this.fastSteps.step2.unidade.lista.forEach((m, index) => {            
                    this.fastSecretariaUnidadesTotal.forEach(t => {
                      if (m == t.id_secretaria_curso_programa_unidade) {
                        let obj = {            
                          id_matricula_solicitacao: res[0].id_matricula_solicitacao,
                          id_programa_turma_unidade: t.id_programa_turma_unidade
                        }             
                        this.promisePostFastApi(obj, "api/fast_secretaria_matricula_aluno_unidade_solicitacao/insere").then(res => {
                          if ((index + 1) == this.fastSteps.step2.unidade.lista.length) {
                            resolve(true);
                            return true;
                          }                 
                        }).catch(() => {
                          this.exibeToasty(e, "error")
                          reject(true)
                          return false                              
                        })
                      }
                    })
                })    
                  
                })
                .catch(e => {
                  this.exibeToasty(e, "error")
                  reject(true)
                  return false
                })   
              } else {
                this.exibeToasty("Escolha uma unidade curricular", "error");
                reject(true)
                return false
              }
              break;        
            default:
              this.exibeToasty("Dados incompletos", "error");
              return false
              break;
          }
        }
        

      });
    },
    async validaSegundoPassoPagamento(geraFatura) {  
      return new Promise(async (resolve, reject) => {   
        if (geraFatura) geraFatura = true
        else
          geraFatura = false

        let erros = []
        if (!this.fastPessoa.id_pessoa) erros.push("Pessoa não identificada")
        console.log("this.fastSteps", this.fastSteps)

        if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          reject(true)
          return false
        } else {
          
          this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_plataforma", "id_plataforma=" + this.$route.params.id_plataforma).then(obj => {       
            let objPessoa = JSON.parse(obj)
            console.log("busca_pessoa_secretaria -> resposta", objPessoa)
            if (objPessoa.id_pessoa) {
              objPessoa.id_plataforma = this.$route.params.id_plataforma

              let erros = []

              if (!objPessoa.cpf_cnpj) erros.push(`O usuário ${objPessoa.nome_razao} não possui CPF/CNPJ cadastrado`)
              
              //if (!objPessoa.cep) erros.push(`O usuário ${objPessoa.nome_razao} não possui endereço completo cadastrado`)

              if (!objPessoa.datanascimento_abertura) erros.push(`O usuário ${objPessoa.nome_razao} não possui a data de nascimento cadastrada`)

              if (!objPessoa.email) erros.push(`O usuário ${objPessoa.nome_razao} não possui email cadastrado`)

              if (!objPessoa.telefone) erros.push(`O usuário ${objPessoa.nome_razao} não possui telefone cadastrado`)

              if (objPessoa.numero) objPessoa.numero = objPessoa.numero.trim()                  

              this.fastSteps.step3.invoice.cpf_responsavel = objPessoa.cpf_cnpj,
              //this.fastSteps.step3.invoice.cep_responsavel = objPessoa.cep,
              this.fastSteps.step3.invoice.endereco_responsavel_numero = objPessoa.numero,
              this.fastSteps.step3.invoice.endereco_responsavel_complemento = objPessoa.complemento,
              this.fastSteps.step3.invoice.ativo = true
              this.fastSteps.step3.invoice.cpf_aluno = objPessoa.cpf_cnpj
              this.fastSteps.step3.invoice.id_pessoa_aluno = objPessoa.id_pessoa   
              this.fastSteps.step3.invoice.id_pessoa_responsavel = objPessoa.id_pessoa
              this.fastSteps.step3.invoice.id_plano_pagamento = this.fastSteps.step3.plano.id_plano_pagamento
              this.fastSteps.step3.invoice.id_plataforma = this.$route.params.id_plataforma
              this.fastSteps.step3.invoice.nome_aluno = objPessoa.nome_razao + " " + objPessoa.sobrenome_fantasia
              this.fastSteps.step3.invoice.nome_responsavel = objPessoa.nome_razao + " " + objPessoa.sobrenome_fantasia

              if (erros.length) {
                erros.forEach(e => this.exibeToasty(e, "error"))
                erros = []
                reject(false)
                return false;
              } else {

                let obj = {
                  fast_pessoa: objPessoa,
                  fast_tesouraria_invoice: this.fastSteps.step3.invoice,
                  fast_tesouraria_invoice_parcela: [],
                  fast_tesouraria_invoice_itens_cobranca: this.fastSteps.step2.itens,
                  fast_usuario: {
                    email: objPessoa.email
                  },
                  fast_pessoa_telefone: {
                    telefone: objPessoa.telefone
                  },
                  fast_curso_plataforma: this.fastSteps.step2.cursos_livres,
                  gera_fatura: geraFatura
                }

                this.fastSteps.step3.plano.parcelas.forEach((parcela, index) => {     
                  let data_vencimento = parcela.data_vencimento
                  if (parcela.data_vencimento.split("T").length > 1) {
                    data_vencimento = parcela.data_vencimento.split("T")[0]
                  } else if (parcela.data_vencimento.split("/").length > 1){
                    data_vencimento = parcela.data_vencimento.split("/")[2] + "-" + parcela.data_vencimento.split("/")[1] + "-" + parcela.data_vencimento.split("/")[0]
                  } 

                  let fast_tesouraria_invoice_parcela = {
                    id_invoice: 0,
                    parcela: index + 1,
                    valor_parcela: parcela.valor_parcela,
                    acrescimo: parcela.acrescimo,
                    desconto: parcela.desconto,
                    total: parcela.total,
                    valor_pago: 0.00,
                    taxa_boleto: parcela.taxa_boleto,
                    taxa_cartao: parcela.taxa_cartao,
                    outras_taxas: parcela.outras_taxas,
                    data_vencimento: data_vencimento,//parcela.data_vencimento.split("/")[1] + "/" + parcela.data_vencimento.split("/")[0] + "/" + parcela.data_vencimento.split("/")[2],
                    path_boleto: null,
                    id_gateway: this.fastSteps.step3.invoice.id_gateway,
                    autenticacao: null,
                    pago: false,
                    log_pagto_auditoria: null,
                    id_tipo_pagamento: this.fastSteps.step3.tipo.id_tipo_pagamento,
                    nr_parcelas: this.fastSteps.step3.plano.parcelas.length
                  }
                  obj.fast_tesouraria_invoice_parcela.push(fast_tesouraria_invoice_parcela)                  

                });

                console.log("objfinal", obj)

                this.$store.state.fastCarregando = true
                this.promisePostFastApi(obj, "api/fast_tesouraria_invoice/valida_invoice_loja")
                .then(res => {                
                  if (res.length){
                    const json = JSON.parse(res)
                    console.log("valida_invoice_loja", json)  
                    // Última pessoa
                      if (!json.error) { 
                        if (!geraFatura) {
                          this.fastSteps.step2.cursos_livres = json.cursos
                          this.fastSteps.step2.total = json.fast_tesouraria_invoice.total

                          let invoice = json.fast_tesouraria_invoice
                          invoice.parcelas = json.parcelas
                          this.fastSteps.step3.invoice = invoice

                          json.tipo_pagamento.loading = false
                          this.fastSteps.step3.tipo = json.tipo_pagamento

                          json.plano_pagamento.loading = false
                          json.plano_pagamento.parcelas = json.parcelas
                          this.fastSteps.step3.plano = json.plano_pagamento

                          this.hidePrevButton()
                          this.hideNextButton()
                        } else {
                          this.fastSteps.step3.invoice.id_invoice = json.id_invoice
                        }
                        

                        this.$store.state.fastCarregando = false 
                        resolve(true);
                        return true;
                      } else {
                        if (json.description.split(",").length) {
                          this.fastErros = json.description.split(",")
                        } else {
                          this.fastErros.push(json.description)
                          console.log("this.fastErros", this.fastErros)
                        }
                        this.$store.state.fastCarregando = false
                        this.showModal('modalErrosRequisicao')
                        reject(false)
                        return false;
                      }
                                          
                  }
                })
                .catch((e) => {                
                  this.exibeToasty(`${e}`, "error")
                  this.$store.state.fastCarregando = false
                  reject(false)
                  return false;
                });

                
              }                  

            } else {
              this.exibeToasty(`Erro ao buscar informações do usuário ${p.first_name}`, "error")
              reject(false)
              return false;
            }
          })
          .catch((e) => {                
            this.exibeToasty(`${e}`, "error")
            reject(false)
            return false;
          });
          
        }
        

      });
    },
    async enviaSolicitacaoMatricula(obj){
      return new Promise(async (resolve, reject) => {    
        this.promisePostFastApi(obj, "api/fast_secretaria_matricula_aluno_solicitacao/insere").then((res) => {
          if (res.length) {
            resolve(res);
            return true;
          } else {
            reject(true)
            return false
          }
        });      
      })
    },
    // Terceiro passo
    getSecretariaPlanoPagamento(id_plataforma){
      if (!this.fastSecretariaPlanoPagamento.length) {
        this.fastSteps.step3.plano.loading = true;
        this.promiseGetFastApi("api/fast_tesouraria_plano_pagamento/lista", "id_plataforma=" + id_plataforma).then(obj => {
          console.log("getSecretariaPlanoPagamento", obj);
          if (obj.length) {
            obj = obj.filter(p => {if (p.ativo) return true})
            this.fastSecretariaPlanoPagamento = obj;
          }
          else this.fastSecretariaPlanoPagamento = [];
          this.fastSteps.step3.plano.loading = false
        }).catch(e => {
          console.log(e);
          this.fastSteps.step3.plano.loading = false
        })
      }      
    },
    getSecretariaTipoPagamento(id_plataforma){
      if (!this.fastSecretariaTipoPagamento.length) {
        this.fastSteps.step3.tipo.loading = true;
        this.promiseGetFastApi("api/fast_tesouraria_tipo_pagamento/lista", "id_plataforma=" + id_plataforma).then(obj => {
          console.log("getSecretariaTipoPagamento", obj);
          if (obj.length) {
            // Filtra somente débito
            this.fastSecretariaTipoPagamento = obj.filter(p => { if (p.operador == 'D') return true });
            //this.fastSecretariaTipoPagamento = obj
          }
          else {
            this.fastSecretariaTipoPagamento = []
          }

          this.fastSteps.step3.tipo.loading = false;
        }).catch(e => {
          console.log(e);
          this.fastSteps.step3.tipo.loading = false;
        })
      }      
    },
    formataFormaPagamento(forma_pagamento) {
      switch (forma_pagamento) {
        case 'C':
          return 'Cartão'
          break;
        case 'B':
          return 'Boleto bancário'
          break;
        default:
          break;
      }
    },
    verificaConfiguracaoPagamento() {     
      if (this.fastSteps.step3.tipo.id_tipo_pagamento && this.fastSteps.step3.plano.id_plano_pagamento) {
        let erros = []

        // Pega o valor subtotal e total dos itens de cobraça
        if (!this.fastSteps.step2.total) erros.push("Valor total da fatura não identificado")

        // Busca o tipo selecionado
        this.fastSecretariaTipoPagamento.forEach(p => {
          if (p.id_tipo_pagamento == this.fastSteps.step3.tipo.id_tipo_pagamento) {
            this.fastSteps.step3.tipo.forma_pagamento = p.forma_pagamento
            this.fastSteps.step3.tipo.id_plano_conta = p.id_plano_conta
            this.fastSteps.step3.tipo.id_plataforma = p.id_plataforma
            this.fastSteps.step3.tipo.nome_tipo_pagamento = p.nome_tipo_pagamento
            this.fastSteps.step3.tipo.operador = p.operador
          }
        });
        if (!this.fastSteps.step3.tipo.forma_pagamento) erros.push("Tipo de pagamento não identificado")
        if (this.fastSteps.step3.tipo.forma_pagamento == "C") erros.push("O tipo de pagamento cartão de crédito não está ativado")

        // Busca o plano selecionado
        this.fastSecretariaPlanoPagamento.forEach(p => {
          if (p.id_plano_pagamento == this.fastSteps.step3.plano.id_plano_pagamento) {
            this.fastSteps.step3.plano.nome_plano = p.nome_plano
            this.fastSteps.step3.plano.obs = p.obs
            this.fastSteps.step3.plano.periocidade_cada_unidade = p.periocidade_cada_unidade
            this.fastSteps.step3.plano.dia_mes = p.dia_mes
            this.fastSteps.step3.plano.tempo_determinado = p.tempo_determinado
            this.fastSteps.step3.plano.parcelas = []
          }
        });
        if (!this.fastSteps.step3.plano.nome_plano) erros.push("Plano de pagamento não identificado")      
        if (!this.fastSteps.step3.plano.tempo_determinado || this.fastSteps.step3.plano.tempo_determinado < 1) erros.push("Plano de pagamento para tempo indeterminado não está ativo")  

        if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastSteps.step3.plano.id_plano_pagamento = 0
          this.fastSteps.step3.tipo.id_tipo_pagamento = 0
        } else {
          this.aplicaAjusteValor()
        }
      }
    },    
    aplicaAjusteValor(){
      let erros = []

      // Ajustes do valor total, o subtotal nunca é alterado
      let valor_total = 0.00
      valor_total = this.fastSteps.step2.total
      this.fastSteps.step3.invoice.subtotal = valor_total

      if (this.fastSteps.step3.invoice.acrescimo == "") this.fastSteps.step3.invoice.acrescimo = 0.00
      if (this.fastSteps.step3.invoice.desconto_total == "") this.fastSteps.step3.invoice.desconto_total = 0.00
      valor_total = parseFloat(this.fastSteps.step3.invoice.subtotal) + parseFloat(this.fastSteps.step3.invoice.acrescimo) - parseFloat(this.fastSteps.step3.invoice.desconto_total)      
      
      let n_parcelas = 0
      n_parcelas = this.fastSteps.step3.plano.tempo_determinado / this.fastSteps.step3.plano.periocidade_cada_unidade 
      
      // Dessa forma arredonda pra cima
      let valor_parcela = parseFloat(valor_total / n_parcelas)      
      valor_parcela = this.arredondaCasasDecimais(valor_parcela)  

      // Dessa forma gera incremento pra ser adicionado na primeira parcela 
      //let valor_parcela = parseFloat(valor_total / n_parcelas).toFixed(2)   
      //let parcelasSomadas = n_parcelas * parseFloat(valor_parcela)
      //let incremento = 0.00

      // Se existir taxas nas parcelas incrementa no valor total
      let taxa_boleto = 0.00
      if (this.fastSteps.step3.invoice.taxa_boleto) taxa_boleto = parseFloat(this.fastSteps.step3.invoice.taxa_boleto)
      let taxa_cartao = 0.00
      if (this.fastSteps.step3.invoice.taxa_cartao) taxa_cartao = parseFloat(this.fastSteps.step3.invoice.taxa_cartao)              
      let outras_taxas = 0.00
      if (this.fastSteps.step3.invoice.outras_taxas) outras_taxas = parseFloat(this.fastSteps.step3.invoice.outras_taxas)
      let taxas_parcela = (taxa_boleto * n_parcelas) + (taxa_cartao * n_parcelas) + (outras_taxas * n_parcelas)

      this.fastSteps.step3.invoice.total = (valor_parcela * n_parcelas) + taxas_parcela   
            
      // Se a soma das parcelas não bater com o valor total original então é necessário calcular um incremento para adicionar no valor total e posteriormente na primeira parcela
      //if (parseFloat(this.fastSteps.step2.total) > parseFloat(parcelasSomadas).toFixed(2)) {
      //  incremento = parseFloat(this.fastSteps.step2.total) - parseFloat(parseFloat(parcelasSomadas).toFixed(2))
      //  this.fastSteps.step3.invoice.total += incremento
      //} 

      // O mínimo valor da parcela é 5 (restrição do gerencianet)
      if (valor_parcela < 5) erros.push("O valor mínimo da parcela é de R$ 5,00")

      // Se o plano possui tempo determinado
      if (!this.fastSteps.step3.plano.tempo_determinado) erros.push("Plano sem tempo determinado não é suportado no momento")

      if (erros.length) {
        erros.forEach(e => this.exibeToasty(e, "error"))
        this.fastSteps.step3.plano.id_plano_pagamento = 0
        this.fastSteps.step3.tipo.id_tipo_pagamento = 0
      } else {
         let dataAtual = new Date()
          // Captura a data do servidor para cálculo das datas das parcelas
          this.getFastHoraServidor()
          .then(() => {
            // Evita problema de fevereiro
            dataAtual = this.$store.state.fastDataAtualServidor          
            if (dataAtual.getDate() > 28) { 
              dataAtual.setMonth(dataAtual.getMonth() + 1)
              dataAtual.setDate(1)
            } 
            if (this.dataPrimeiraParcela) dataAtual = new Date(this.dataPrimeiraParcela.split("-")[0] + "/" + this.dataPrimeiraParcela.split("-")[1] + "/" + this.dataPrimeiraParcela.split("-")[2])
            return dataAtual
          })
          .then(dataAtual => {    
              // Monta as parcelas
              this.fastSteps.step3.plano.parcelas = []
              this.fastSteps.step3.invoice.parcelas = []
              for (let index = 0; index < n_parcelas; index++) {

                // Se periodicidade for mês seta do vencimento por mês
                if (this.fastSteps.step3.plano.dia_mes == "M") {
                  if (index) {       
                      dataAtual.setMonth( dataAtual.getMonth() + this.fastSteps.step3.plano.periocidade_cada_unidade );  
                  }
                // Se periodicidade for dia seta do vencimento por dia
                } else if (this.fastSteps.step3.plano.dia_mes == "D") {
                  if (index) dataAtual = this.addDays(dataAtual, this.fastSteps.step3.plano.periocidade_cada_unidade );
                }

                // Cria objeto da parcela
                let objParcela = {
                  parcela: index + 1,
                  total: this.fastSteps.step3.invoice.total,
                  valor_parcela: valor_parcela,
                  datetime: dataAtual,
                  data_formatada: dataAtual.toLocaleString('pt-BR').split(" ")[0],
                  data_vencimento: dataAtual.toLocaleString('pt-BR').split(" ")[0],
                  acrescimo: 0.00,
                  desconto: 0.00,
                  taxa_boleto: taxa_boleto,
                  taxa_cartao: taxa_cartao,
                  outras_taxas: outras_taxas,
                  nr_parcelas: n_parcelas                  
                } 

                // Se existir incremento adiciona na primeira parcela
                //if (incremento > 0 && index == 0)   objParcela.valor_parcela = parseFloat(valor_parcela) + parseFloat(incremento)
                
                // Adiciona objeto parcela no array de parcelas do plano e invoice
                this.fastSteps.step3.plano.parcelas.push(objParcela)
                this.fastSteps.step3.invoice.parcelas.push(objParcela)

              }
          })
          .catch((e) => this.exibeToasty(e, "error"))
      }
    },
    definePrimeiraParcela() {
      if (this.dataPrimeiraParcela.split("-").length) {
        this.verificaConfiguracaoPagamento()
        this.hideModal("modalDefinirPrimeiraParcela")
      }      
    },
    aplicaAcrescimo(destino) {      
      let total = 0.00

      if (this.fastSteps.step3.invoice.acrescimo == "") this.fastSteps.step3.invoice.acrescimo = 0.00
      if (this.fastSteps.step3.invoice.desconto_total == "") this.fastSteps.step3.invoice.desconto_total = 0.00
      if (this.fastSteps.step3.invoice.total == "") this.fastSteps.step3.invoice.total = 0.00

      if (destino == 'total') {
        total = parseFloat(this.fastSteps.step3.invoice.subtotal) + parseFloat(this.fastSteps.step3.invoice.acrescimo) - parseFloat(this.fastSteps.step3.invoice.desconto_total)
        this.fastSteps.step3.invoice.total = total
        this.aplicaAjusteParcelas();
      } 
    },
    aplicaAjusteParcelas() {
      this.$store.state.fastCarregando = true;
      let dataAtual = new Date();
      this.promiseGetFastApi("api/fast_usuario_curso_aula_atividade/lista_hora", "").then(obj => {
        if (JSON.parse(obj).dataAtual) {
          dataAtual = new Date(JSON.parse(obj).dataAtual);
          if (this.dataPrimeiraParcela) dataAtual = new Date(this.dataPrimeiraParcela.split("-")[0] + "/" + this.dataPrimeiraParcela.split("-")[1] + "/" + this.dataPrimeiraParcela.split("-")[2])
          // Evita problema de fevereiro
          if (dataAtual.getDate() > 28) { 
            dataAtual.setMonth(dataAtual.getMonth() + 1)
            dataAtual.setDate(1)
          }
          this.fastSteps.step3.plano.parcelas = []
          if (this.fastSteps.step3.plano.tempo_determinado) {
            let n_parcelas = 0.00
            n_parcelas = this.fastSteps.step3.plano.tempo_determinado / this.fastSteps.step3.plano.periocidade_cada_unidade 

            for (let index = 0; index < n_parcelas; index++) {
              if (this.fastSteps.step3.plano.dia_mes == "M") {
                if (index) dataAtual.setMonth( dataAtual.getMonth() + this.fastSteps.step3.plano.periocidade_cada_unidade );
              } else if (this.fastSteps.step3.plano.dia_mes == "D") {
                if (index) dataAtual = this.addDays(dataAtual, this.fastSteps.step3.plano.periocidade_cada_unidade );
              }

              let objParcela = {
                parcela: index + 1,
                total: this.fastSteps.step3.invoice.total,
                valor_parcela: this.removeCasasDecimais(parseFloat(this.fastSteps.step3.invoice.total) / n_parcelas, 2),
                datetime: dataAtual,
                data_formatada: dataAtual.toLocaleString('pt-BR').split(" ")[0],
                data_vencimento: dataAtual.toLocaleString('pt-BR').split(" ")[0],
                acrescimo: 0.00,
                desconto: 0.00,
                taxa_boleto: 0.00,
                taxa_cartao: 0.00,
                outras_taxas: 0.00,
                nr_parcelas: n_parcelas                  

              }                
             
              this.fastSteps.step3.plano.parcelas.push(objParcela)
            }

            if (this.fastSteps.step3.plano.parcelas.length) {                
              let parcelasSomadas = this.fastSteps.step3.plano.parcelas.length * parseFloat(this.fastSteps.step3.plano.parcelas[0].valor_parcela)               
              console.log("parcelasSomadas", parcelasSomadas)
              if (parseFloat(this.fastSteps.step3.invoice.total) > parseFloat(parcelasSomadas).toFixed(2)) {
                let incremento = parseFloat(this.fastSteps.step3.invoice.total) - parseFloat(parseFloat(parcelasSomadas).toFixed(2))
                this.fastSteps.step3.plano.parcelas[0].valor_parcela = parseFloat(this.fastSteps.step3.plano.parcelas[0].valor_parcela) + parseFloat(incremento.toFixed(2))
              } 
            }
          }
        }
        this.$store.state.fastCarregando = false;
      });
      
    },
    async validaTerceiroPasso() {
      return new Promise(async (resolve, reject) => {
        if (this.fastSteps.step3.plano.id_plano_pagamento && this.fastSteps.step3.tipo.id_tipo_pagamento) {
          this.fastSecretariaTipoPagamento.forEach(t => {
            if (t.id_tipo_pagamento == this.fastSteps.step3.tipo.id_tipo_pagamento) {
              this.fastSteps.step3.tipo.nome_tipo_pagamento = t.nome_tipo_pagamento
              this.fastSteps.step3.tipo.forma_pagamento = t.forma_pagamento
            }
          })

          if (this.fastSteps.step3.matricula.valor_matricula) {
            if (this.fastSteps.step3.matricula.valor_matricula > 5) {
              //this.getFastPlataformaAlunoResponsavel(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
              resolve(true);
              return true;
            } else {
              this.exibeToasty("O valor da matrícula deve ser superior a R$ 5,00", "error");
              reject(true)
              return false
            }
          } else {
            //this.getFastPlataformaAlunoResponsavel(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
            resolve(true);
            return true;
          }
          
        } else if (this.fastSteps.step3.pular_pagamento) {
          //this.getFastPlataformaAlunoResponsavel(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
          resolve(true);
          return true;
        } else {
          this.exibeToasty("Escolha um plano e um tipo de pagamento", "error");
          reject(true)
          return false
        }          
      });
    },
    async gerarFatura(){
      return new Promise(async (resolve, reject) => {  
        this.validaSegundoPassoPagamento(true)
        .then(e => {
          this.showNextButton()
          resolve(true)
          return true
        })
        .catch(e => this.exibeToasty(e, "error"))
      });
    },
    // Quarto passo
    defineAlunoResponsavel() {
      if (this.fastSteps.step4.aluno_responsavel) {
        this.fastSteps.step4.id_pessoa_responsavel = this.fastPessoa.id_pessoa
        this.fastSteps.step4.telefone_responsavel = this.fastPessoa.telefone
        this.fastSteps.step4.nome_responsavel = this.fastPessoa.first_name + " " + this.fastPessoa.last_name
        this.fastSteps.step3.invoice.nome_responsavel = this.fastPessoa.first_name + " " + this.fastPessoa.last_name
      }
    },
    async validaQuartoPasso() {
      return new Promise(async (resolve, reject) => {
        let count = 0
        if (!this.fastSteps.step4.aluno_responsavel) {
          this.$store.state.fastPessoasResponsaveis.lista.forEach(r => {
            if (r.responsavel_financeiro) {
              count++
              this.fastSteps.step4.id_pessoa_responsavel = r.id_pessoa_responsavel
              this.fastSteps.step4.nome_responsavel = r.nome_responsavel
              this.fastSteps.step4.telefone_responsavel = r.celular
              this.fastSteps.step3.invoice.nome_responsavel = r.nome_responsavel
            }
          })
          if (!count) {
            this.fastSteps.step4.id_pessoa_responsavel = 0
            this.fastSteps.step4.nome_responsavel = ""
            this.fastSteps.step4.telefone_responsavel = ""
            this.fastSteps.step3.invoice.nome_responsavel = ""
          }
        }
          
          if (count > 1) {
            this.exibeToasty("Defina apenas um responsável financeiro", "error");
            reject(true)
            return false
          }
          else if (this.fastSteps.step4.id_pessoa_responsavel) {
            this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", "id_pessoa=" + this.fastSteps.step4.id_pessoa_responsavel + "&id_plataforma=" + this.$route.params.id_plataforma).then(obj => {  
              console.log("busca_pessoa_secretaria", JSON.parse(obj))     
              let objPessoa = JSON.parse(obj)
              if (objPessoa.id_pessoa) {    
                let erros = []
                  if (!objPessoa.first_name) erros.push("O nome do responsavel não foi cadastrado")
                  if (!objPessoa.last_name) erros.push("O sobrenome do responsavel não foi cadastrado")
                  if (!objPessoa.cpf_cnpj) erros.push("O CPF/CNPJ do aluno responsavel foi cadastrado")
                  if (!objPessoa.logradouro) erros.push("O endereço do responsavel não foi cadastrado")
                  if (!objPessoa.numero) erros.push("O número do endereço do responsavel não foi cadastrado")
                  if (!objPessoa.bairro) erros.push("O bairro do responsavel não foi cadastrado")
                  if (!objPessoa.cep) erros.push("O CEP do responsavel não foi cadastrado")
                  if (!objPessoa.cidade) erros.push("O cidade do responsavel não foi cadastrado")
                  if (!objPessoa.uf) erros.push("O estado do responsavel não foi cadastrado")
                  if (!objPessoa.telefone) erros.push("O telefone do responsavel não foi cadastrado")
                  if (!objPessoa.datanascimento_abertura) erros.push("A data de nascimento do responsavel não foi cadastrada")
                  if (erros.length) {
                    erros.forEach(e => this.exibeToasty(e, "error"))
                    reject(true)
                    return false
                  } else {
                    this.getSecretariaContratosCurso(this.$route.params.id_plataforma, this.fastSteps.step1.curso.id_secretaria_curso)   
                    resolve(true);
                    return true;
                  }                  
              } else {
                this.exibeToasty("Defina um responsável financeiro", "error");
                reject(true)
                return false
              }
            }).catch(e => {
              this.exibeToasty("Erro ao buscar dados do responsável definido", "error")
              reject(true)
              return false
            })                                
            
          } else {
            this.exibeToasty("Defina um responsável financeiro", "error");
            reject(true)
            return false
          }
          
      });
    },
    // Quinto passo
    async gerarMatricula() {     
      // Verificações
      this.statusGeracaoMatricula.iniciou = true;
      this.hidePrevButton();
      this.hideNextButton();

      let errosMatricula = []
      
      // Verificação do curso
      this.statusGeracaoMatricula.definicao_curso.status = "executing"
      this.statusGeracaoMatricula.definicao_curso.message = "Verificando..."
      await new Promise(done => setTimeout(() => done(), 500));  
      if (this.fastSteps.step1.curso.id_secretaria_curso && this.fastSteps.step1.curso.nome_curso) {          
        this.statusGeracaoMatricula.definicao_curso.status = "ok"
        this.statusGeracaoMatricula.definicao_curso.message = this.fastSteps.step1.curso.nome_curso
      } else {
        this.statusGeracaoMatricula.definicao_curso.status = "error"
        this.statusGeracaoMatricula.definicao_curso.message = "Curso não identificado"    
        errosMatricula.push(this.statusGeracaoMatricula.definicao_curso.message)    
      }

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton()        
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação da turma
        this.statusGeracaoMatricula.definicao_turma.status = "executing"
        this.statusGeracaoMatricula.definicao_turma.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500));  
        if (this.fastSteps.step1.turma.id_turma) {          
          this.statusGeracaoMatricula.definicao_turma.status = "ok"
          this.statusGeracaoMatricula.definicao_turma.message = this.fastSteps.step1.turma.sigla_turma + " - " + this.fastSteps.step1.turma.turno
        } else {
          this.statusGeracaoMatricula.definicao_turma.status = "error"
          this.statusGeracaoMatricula.definicao_turma.message = "Turma não identificada"
          errosMatricula.push(this.statusGeracaoMatricula.definicao_turma.message)  
        }
      }
      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação do plano
        this.statusGeracaoMatricula.definicao_plano.status = "executing"
        this.statusGeracaoMatricula.definicao_plano.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500));  
        if (this.fastSteps.step2.plano == "curso") {
          this.statusGeracaoMatricula.definicao_plano.status = "ok"
          this.statusGeracaoMatricula.definicao_plano.message = "Curso completo"
        } else if (this.fastSteps.step2.plano == "modulo") {
          if (this.fastSteps.step2.modulo.lista.length) {
            this.statusGeracaoMatricula.definicao_plano.status = "ok"
            this.statusGeracaoMatricula.definicao_plano.message = this.fastSteps.step2.modulo.lista.length + " módulo(s) escolhido(s)"
          } else {
            this.statusGeracaoMatricula.definicao_plano.status = "error"
            this.statusGeracaoMatricula.definicao_plano.message = "Nenhum módulo foi escolhido"
            errosMatricula.push(this.statusGeracaoMatricula.definicao_plano.message)
          }
        } else if (this.fastSteps.step2.plano == "unidade") {
          if (this.fastSteps.step2.unidade.lista.length) {
            this.statusGeracaoMatricula.definicao_plano.status = "ok"
            this.statusGeracaoMatricula.definicao_plano.message = this.fastSteps.step2.unidade.lista.length + " unidades(s) escolhida(s)"
          } else {
            this.statusGeracaoMatricula.definicao_plano.status = "error"
            this.statusGeracaoMatricula.definicao_plano.message = "Nenhuma unidade curricular foi escolhida"
            errosMatricula.push(this.statusGeracaoMatricula.definicao_plano.message)
          }
        }
      }      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação do plano de pagamento
        this.statusGeracaoMatricula.definicao_plano_pagamento.status = "executing"
        this.statusGeracaoMatricula.definicao_plano_pagamento.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500));  
        if (this.fastSteps.step3.plano.id_plano_pagamento) {          
          this.statusGeracaoMatricula.definicao_plano_pagamento.status = "ok"
          this.statusGeracaoMatricula.definicao_plano_pagamento.message = this.fastSteps.step3.plano.nome_plano
        } else {
          if (this.fastSteps.step3.pular_pagamento) {
            this.statusGeracaoMatricula.definicao_plano_pagamento.status = "ok"
            this.statusGeracaoMatricula.definicao_plano_pagamento.message = "Matrícula sem pagamento"
          } else {
            this.statusGeracaoMatricula.definicao_plano_pagamento.status = "error"
            this.statusGeracaoMatricula.definicao_plano_pagamento.message = "Plano de pagamento não identificada"
            errosMatricula.push(this.statusGeracaoMatricula.definicao_plano_pagamento.message)
          }        
        }
      }
      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação do tipo de pagamento
        this.statusGeracaoMatricula.definicao_tipo_pagamento.status = "executing"
        this.statusGeracaoMatricula.definicao_tipo_pagamento.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500));  
        if (this.fastSteps.step3.tipo.id_tipo_pagamento) {          
          this.statusGeracaoMatricula.definicao_tipo_pagamento.status = "ok"
          this.statusGeracaoMatricula.definicao_tipo_pagamento.message = this.fastSteps.step3.tipo.nome_tipo_pagamento
        } else {
          if (this.fastSteps.step3.pular_pagamento) {
            this.statusGeracaoMatricula.definicao_tipo_pagamento.status = "ok"
            this.statusGeracaoMatricula.definicao_tipo_pagamento.message = "Matrícula sem pagamento"
          } else {
            this.statusGeracaoMatricula.definicao_tipo_pagamento.status = "error"
            this.statusGeracaoMatricula.definicao_tipo_pagamento.message = "Plano de pagamento não identificada"
            errosMatricula.push(this.statusGeracaoMatricula.definicao_tipo_pagamento.message)
          }        
        }
      }      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        this.statusGeracaoMatricula.definicao_responsavel.status = "executing"
        this.statusGeracaoMatricula.definicao_responsavel.message = "Verificando..."
        await new Promise(done => setTimeout(() => done(), 500))        
        if (this.fastSteps.step4.id_pessoa_responsavel && this.fastSteps.step4.nome_responsavel) {
          this.statusGeracaoMatricula.definicao_responsavel.status = "ok"
          this.statusGeracaoMatricula.definicao_responsavel.message = this.fastSteps.step4.nome_responsavel
        } else {
          this.statusGeracaoMatricula.definicao_responsavel.status = "error"
          this.statusGeracaoMatricula.definicao_responsavel.message = "Responsável financeiro não identificado"
          errosMatricula.push(this.statusGeracaoMatricula.definicao_responsavel.message)
        }
      }      

      if (errosMatricula.length) {
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        // Verificação e geração da matrícula
        this.statusGeracaoMatricula.matricula_gerada.status = "executing"
        this.statusGeracaoMatricula.matricula_gerada.message = "Verificando..."

        await new Promise(done => setTimeout(() => done(), 500)); 

        this.fastSteps.step3.matricula.tipo_matricula = this.fastSteps.step1.curso.nivel.trim() ? this.fastSteps.step1.curso.nivel.trim() : errosMatricula.push("O nível do curso não foi identificado")
        this.fastSteps.step3.matricula.id_pessoa_aluno = this.fastPessoa.id_pessoa ? this.fastPessoa.id_pessoa : errosMatricula.push("O id_pessoa do aluno não foi identificado")
        this.fastSteps.step3.matricula.RA =  this.fastSteps.step3.matricula.RA ? this.fastSteps.step3.matricula.RA : null

        if (this.fastSteps.step3.matricula.valor_matricula) {
          if (this.fastSteps.step3.matricula.valor_matricula < 5.00) {
            errosMatricula.push("O valor da matrícula não pode ser inferior a R$ 5,00")
          }
          else {
            this.statusGeracaoMatricula.matricula_gerada.message = "Valor da matrícula: R$ " + this.formataPreco(this.fastSteps.step3.matricula.valor_matricula) + " ..."
            await new Promise(done => setTimeout(() => done(), 500))
          }            
        } else {
          this.fastSteps.step3.matricula.valor_matricula = 0          
          this.statusGeracaoMatricula.matricula_gerada.message = "Taxa de matrícula gratuita..."
          await new Promise(done => setTimeout(() => done(), 500))
        }

        if (this.fastSteps.step3.matricula.ativo) {
          this.fastSteps.step3.matricula.ativo = true
        } else {
          this.fastSteps.step3.matricula.ativo = false
        }

        this.fastSteps.step3.matricula.vencimento_matricula = this.fastSteps.step3.matricula.vencimento_matricula ? this.fastSteps.step3.matricula.vencimento_matricula : null
        this.fastSteps.step3.matricula.desconto_matricula = this.fastSteps.step3.matricula.desconto_matricula ? this.fastSteps.step3.matricula.desconto_matricula : 0
        this.fastSteps.step3.matricula.outros_valores = this.fastSteps.step3.matricula.outros_valores ? this.fastSteps.step3.matricula.outros_valores : 0
        this.fastSteps.step3.matricula.curso_definido = true
        this.fastSteps.step3.matricula.plano_pagto_definido = true
        this.fastSteps.step3.matricula.ajuste_definido = true
        this.fastSteps.step3.matricula.responsavel_definido = true
        this.fastSteps.step3.matricula.contrato_gerado = false
        this.fastSteps.step3.matricula.boleto_gerado = false
        this.fastSteps.step3.matricula.id_plataforma = this.$route.params.id_plataforma
        this.fastSteps.step3.matricula.id_turma = this.fastSteps.step1.turma.id_turma
        this.fastSteps.step3.matricula.id_invoice = this.fastSteps.step1.turma.id_invoice ? this.fastSteps.step1.turma.id_invoice : 0
        this.fastSteps.step3.matricula.ano_matricula = new Date().getFullYear()
        this.fastSteps.step3.matricula.pago = false
      }

      if (errosMatricula.length) {
        this.statusGeracaoMatricula.matricula_gerada.status = "error"
        this.statusGeracaoMatricula.matricula_gerada.message = errosMatricula.join(", ")
        errosMatricula.forEach(e => this.exibeToasty(e, "error"))
        this.showPrevButton() 
        this.showNextButton()
        this.statusGeracaoMatricula.iniciou = false
      } else {
        if (this.fastSteps.step2.plano == "curso") {
          this.fastSteps.step3.matricula.matricula_por_programa = false
          this.fastSteps.step3.matricula.matricula_por_unidade = false
        } else if (this.fastSteps.step2.plano == "modulo") {
          this.fastSteps.step3.matricula.matricula_por_programa = true
          this.fastSteps.step3.matricula.matricula_por_unidade = false
        } else if (this.fastSteps.step2.plano == "unidade") {
          this.fastSteps.step3.matricula.matricula_por_programa = false
          this.fastSteps.step3.matricula.matricula_por_unidade = true
        } 
      }  

      this.statusGeracaoMatricula.matricula_gerada.message = "Buscando dados do responsável..."

      let objPessoaResponsavel = {}
      this.getFastHoraServidor()
      .then(() => {
        return this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", "id_pessoa=" + this.fastSteps.step4.id_pessoa_responsavel + "&id_plataforma=" + this.$route.params.id_plataforma)
      })
      .then(obj => {     
          let erros = []  
          objPessoaResponsavel = JSON.parse(obj)
          if (objPessoaResponsavel.id_pessoa) {    
            objPessoaResponsavel.id_plataforma = this.$route.params.id_plataforma

            // Validações da pessoa resposnável
            if (!objPessoaResponsavel.nome_razao) erros.push(`O responsável não possui nome cadastrado`)    

            if (!objPessoaResponsavel.cpf_cnpj) erros.push(`O responsável não possui CPF/CNPJ cadastrado`)            
            if (objPessoaResponsavel.cpf_cnpj) {
              if (!this.validaCpfCnpj(objPessoaResponsavel.cpf_cnpj)) erros.push("CPF/CNPJ inválido")
            }    

            if (!objPessoaResponsavel.cep) erros.push(`O responsável não possui endereço completo cadastrado`)
            if (objPessoaResponsavel.cep) {
              if (!/^[0-9]{8}$/.test(objPessoaResponsavel.cep.replace("-", ""))) erros.push("CEP inválido")
            }  
            
            if (!objPessoaResponsavel.datanascimento_abertura) erros.push(`O responsável não possui a data de nascimento cadastrada`)
            if (objPessoaResponsavel.datanascimento_abertura) {
              let objDataNasc = new Date(`${objPessoaResponsavel.datanascimento_abertura}T00:00:00`)
              let cincoAnosAtras = new Date(this.$store.state.fastDataAtualServidor.setFullYear(this.$store.state.fastDataAtualServidor.getFullYear() - 5))
              if (objDataNasc > cincoAnosAtras) erros.push("A data de nascimento deve ser superior a 5 anos atrás")
            }

            if (!objPessoaResponsavel.email) erros.push(`O responsável não possui email cadastrado`)

            if (!objPessoaResponsavel.telefone) erros.push(`O responsável não possui telefone cadastrado`)
            if (!this.validaTelefoneFormatado(objPessoaResponsavel.telefone)) erros.push("O telefone está no formato incorreto")

            if (objPessoaResponsavel.numero) objPessoaResponsavel.numero = objPessoaResponsavel.numero.trim()  
           
          } else {
            erros.push("Erro ao retornar dados do usuário")
          }
          return erros
      })
      .then(erros => {
        if (erros.length) {
          this.statusGeracaoMatricula.matricula_gerada.status = "error"
          this.statusGeracaoMatricula.matricula_gerada.message = erros.join(", ")
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.showPrevButton() 
          this.showNextButton()
          this.statusGeracaoMatricula.iniciou = false
        } else {
          
            // Outras validações
          this.fastSteps.step3.invoice.id_pessoa_responsavel = this.fastSteps.step4.id_pessoa_responsavel
          this.fastSteps.step3.invoice.cpf_responsavel = objPessoaResponsavel.cpf_cnpj
          this.fastSteps.step3.invoice.cep_responsavel = objPessoaResponsavel.cep
          this.fastSteps.step3.invoice.endereco_responsavel_numero = objPessoaResponsavel.numero.trim()
          this.fastSteps.step3.invoice.endereco_responsavel_complemento = objPessoaResponsavel.complemento
          this.fastSteps.step3.invoice.ativo = true
          this.fastSteps.step3.invoice.cpf_aluno = this.fastPessoa.cpf_cnpj   

          this.fastSteps.step3.invoice.id_plano_pagamento = this.fastSteps.step3.plano.id_plano_pagamento
          this.fastSteps.step3.invoice.id_plataforma = this.$route.params.id_plataforma
          this.fastSteps.step3.invoice.nome_aluno = this.fastPessoa.nome_razao + " " + this.fastPessoa.sobrenome_fantasia
          this.fastSteps.step3.invoice.obs = null

          this.fastSteps.step3.invoice.id_pessoa_aluno = this.fastPessoa.id_pessoa

          let obj = {
            fast_secretaria_matricula_aluno: this.fastSteps.step3.matricula,
            fast_tesouraria_invoice: this.fastSteps.step3.invoice,
            fast_tesouraria_invoice_parcela: [],
            fast_pessoa: objPessoaResponsavel,
            fast_usuario: {
              email: objPessoaResponsavel.email
            },
            fast_pessoa_telefone: {
              telefone: objPessoaResponsavel.telefone
            },
            fast_secretaria_curso: {
              nome_curso: this.fastSteps.step1.curso.nome_curso ? this.fastSteps.step1.curso.nome_curso : "Curso"
            }
          }

          if (!this.fastSteps.step3.plano.parcelas.length && !this.fastSteps.step3.pular_pagamento) erros.push(`O número de parcelas não foi identificado`)          

          this.fastSteps.step3.plano.parcelas.forEach((p, index) => {   
            // Validação de parcelas
            if (p.valor_parcela) {
              if (p.valor_parcela < 5.00) erros.push(`O valor da parcela ${index + 1} é inferior a R$ 5,00`)
            } else {
              erros.push(`Valor da parcela ${index + 1} não identificado`)
            }
            if (p.total) {
              if (p.total < 5.00) erros.push(`O valor total é inferior a R$ 5,00`)
            } else {
              erros.push(`Valor total não foi identificado`)
            }

            let dataAtual = new Date(this.$store.state.fastDataAtualServidor)
            if (p.data_vencimento) {
              let objDataVenc = new Date(`${p.data_vencimento}T00:00:00`)                  
              if (objDataVenc < dataAtual) erros.push(`A data de vencimento da parcela ${index + 1} está no passado`)
            }

            if (!p.acrescimo) p.acrescimo = 0
            if (!p.desconto) p.desconto = 0
            if (!p.taxa_boleto) p.taxa_boleto = 0
            if (!p.taxa_cartao) p.taxa_cartao = 0
            if (!p.outras_taxas) p.outras_taxas = 0

            if (!this.fastSteps.step3.tipo.id_tipo_pagamento) erros.push(`O tipo de pagamento na parcela não foi identificado`)

            let fast_tesouraria_invoice_parcela = {
              id_invoice: 0,
              parcela: index + 1,
              valor_parcela: p.valor_parcela,
              acrescimo: p.acrescimo,
              desconto: p.desconto,
              total: p.total,
              valor_pago: 0.00,
              taxa_boleto: p.taxa_boleto,
              taxa_cartao: p.taxa_cartao,
              outras_taxas: p.outras_taxas,
              data_vencimento: p.data_vencimento.split("/")[1] + "/" + p.data_vencimento.split("/")[0] + "/" + p.data_vencimento.split("/")[2],
              path_boleto: null,
              id_gateway: 0,
              autenticacao: null,
              pago: false,
              log_pagto_auditoria: null,
              id_tipo_pagamento: this.fastSteps.step3.tipo.id_tipo_pagamento,
              nr_parcelas: this.fastSteps.step3.plano.parcelas.length
            }
            obj.fast_tesouraria_invoice_parcela.push(fast_tesouraria_invoice_parcela)

        });
        
          if (erros.length) {
            this.statusGeracaoMatricula.matricula_gerada.status = "error"
            this.statusGeracaoMatricula.matricula_gerada.message = erros.join(", ")
            erros.forEach(e => this.exibeToasty(e, "error"))
            this.showPrevButton() 
            this.showNextButton() 
            this.statusGeracaoMatricula.iniciou = false
          } else {
            this.showPrevButton()
            this.showNextButton() 
            this.statusGeracaoMatricula.iniciou = false
            // Matrícula sem invoice
            if (this.fastSteps.step3.pular_pagamento) {
              
              this.promisePostFastApi(obj.fast_secretaria_matricula_aluno, "api/fast_secretaria_matricula_aluno/insere")
              .then(res => {                
                if (res.length){
                  const json = res[0]                  
                  if (json.id_matricula) {
                    this.fastSteps.step3.matricula.id_matricula = json.id_matricula
                    this.fastSteps.step3.matricula.id_invoice = json.id_invoice_matricula
                    this.fastSteps.step3.invoice.id_matricula = json.id_matricula                    
                    this.statusGeracaoMatricula.matricula_gerada.status = "ok"
                    this.statusGeracaoMatricula.matricula_gerada.message = "Matrícula #" + this.fastSteps.step3.matricula.id_matricula + " gerada"

                    // Se for módulo
                    if (this.fastSteps.step2.plano == "modulo") {
                    this.statusGeracaoMatricula.insercao_modulos.status = "executing"
                    this.statusGeracaoMatricula.insercao_modulos.message = "Cadastrando..."

                    // Gravar em [fastead].[fast_secretaria_matricula_aluno_programa]
                    let errosModulos = 0
                    this.fastSteps.step2.modulo.lista.forEach((m, index) => {            
                        this.fastSecretariaModulosTotal.forEach(t => {
                          if (m == t.id_secretaria_curso_programa) {
                            let objMatriculaAlunoPrograma = {
                              id_plataforma: this.$route.params.id_plataforma,
                              fast_secretaria_matricula_aluno_programa: {            
                                id_matricula: this.fastSteps.step3.matricula.id_matricula,
                                id_programa_turma: t.id_programa_turma
                              }
                            }                  
                            console.log("objMatriculaAlunoPrograma", objMatriculaAlunoPrograma)
                            this.insereMatriculaAlunoPrograma(objMatriculaAlunoPrograma).then(res => {
                              if ((index + 1) == this.fastSteps.step2.modulo.lista.length && !errosModulos) {
                                this.statusGeracaoMatricula.insercao_modulos.status = "ok"
                                this.statusGeracaoMatricula.insercao_modulos.message = this.fastSteps.step2.modulo.lista.length + " módulo(s) cadastrados(s)"
                              }                 
                            }).catch(() => {
                              errosModulos++
                              this.statusGeracaoMatricula.insercao_modulos.status = "error"
                              this.statusGeracaoMatricula.insercao_modulos.message = "Matrícula gerada mas ocorreu um erro ao cadastrar os módulos. Informe o id_matricula #" + this.fastSteps.step3.matricula.id_matricula + " ao administrador do sistema."
                              this.showPrevButton() 
                              this.showNextButton()
                            })
                          }
                        })
                    })
                    
                    } else if (this.fastSteps.step2.plano == "unidade") {
                      this.statusGeracaoMatricula.insercao_unidades.status = "executing"
                      this.statusGeracaoMatricula.insercao_unidades.message = "Cadastrando..."

                      // Gravar em [fastead].[fast_secretaria_matricula_aluno_unidade]
                      let errosUnidades = 0;
                      this.fastSteps.step2.unidade.lista.forEach((m, index) => {            
                          this.fastSecretariaUnidadesTotal.forEach(t => {
                            if (m == t.id_secretaria_curso_programa_unidade) {
                              let objMatriculaAlunoUnidade = {
                                id_plataforma: this.$route.params.id_plataforma,
                                fast_secretaria_matricula_aluno_unidade: {            
                                  id_matricula: this.fastSteps.step3.matricula.id_matricula,
                                  id_programa_turma_unidade: t.id_programa_turma_unidade
                                }
                              }                  
                              console.log("objMatriculaAlunoUnidade", objMatriculaAlunoUnidade)
                              this.insereMatriculaAlunoUnidade(objMatriculaAlunoUnidade).then(res => {
                                if ((index + 1) == this.fastSteps.step2.unidade.lista.length && !errosUnidades) {
                                  this.statusGeracaoMatricula.insercao_unidades.status = "ok"
                                  this.statusGeracaoMatricula.insercao_unidades.message = this.fastSteps.step2.unidade.lista.length + " unidades(s) cadastradas(s)"
                                }                 
                              }).catch(() => {
                                errosUnidades++
                                this.statusGeracaoMatricula.insercao_modulos.status = "error"
                                this.statusGeracaoMatricula.insercao_modulos.message = "Matrícula gerada mas ocorreu um erro ao cadastrar as unidades curriculares. Informe o id_matricula #" + this.fastSteps.step3.matricula.id_matricula + " ao administrador do sistema."
                                this.showPrevButton() 
                                this.showNextButton()
                              })
                            }
                          })
                      })          
                    }
                    
                    this.statusGeracaoMatricula.iniciou = false
                    this.showNextButton()

                  } else {    
                    this.showModal('modalErrosRequisicao')
                    this.showPrevButton() 
                    this.showNextButton()
                    this.statusGeracaoMatricula.matricula_gerada.status = "error"
                    this.statusGeracaoMatricula.matricula_gerada.message = "Erro ao gerar matrícula. Consulte um administrador do sistema."
                  }
                }        
              })
              .catch(e => {
                this.exibeToasty(e, "error")
                this.statusGeracaoMatricula.matricula_gerada.status = "error"
                this.statusGeracaoMatricula.matricula_gerada.message = "Erro ao gerar matrícula. Consulte um administrador do sistema."
                this.showPrevButton() 
                this.showNextButton() 
              })
              
            } else {    
            // Matrícula com invoice    
                  
              this.promisePostFastApi(obj, "api/fast_secretaria_matricula_aluno/insere_matricula")
              .then(res => {                
                if (res.length){
                  const json = JSON.parse(res)
                  
                  if (json.status) {
                    this.fastSteps.step3.matricula.id_matricula = json.id_matricula
                    this.fastSteps.step3.matricula.id_invoice = json.id_invoice_matricula
                    this.fastSteps.step3.invoice.id_matricula = json.id_matricula                    
                    this.statusGeracaoMatricula.matricula_gerada.status = "ok"
                    this.statusGeracaoMatricula.matricula_gerada.message = "Matrícula #" + this.fastSteps.step3.matricula.id_matricula + " gerada"

                    this.fastSteps.step3.invoice.id_invoice = json.id_invoice_curso
                    this.statusGeracaoMatricula.insercao_invoice.status = "ok"
                    this.statusGeracaoMatricula.insercao_invoice.message = "Invoice #" + this.fastSteps.step3.invoice.id_invoice + " gerado"

                    // Se for módulo
                    if (this.fastSteps.step2.plano == "modulo") {
                    this.statusGeracaoMatricula.insercao_modulos.status = "executing"
                    this.statusGeracaoMatricula.insercao_modulos.message = "Cadastrando..."

                    // Gravar em [fastead].[fast_secretaria_matricula_aluno_programa]
                    let errosModulos = 0
                    this.fastSteps.step2.modulo.lista.forEach((m, index) => {            
                        this.fastSecretariaModulosTotal.forEach(t => {
                          if (m == t.id_secretaria_curso_programa) {
                            let objMatriculaAlunoPrograma = {
                              id_plataforma: this.$route.params.id_plataforma,
                              fast_secretaria_matricula_aluno_programa: {            
                                id_matricula: this.fastSteps.step3.matricula.id_matricula,
                                id_programa_turma: t.id_programa_turma
                              }
                            }                  
                            console.log("objMatriculaAlunoPrograma", objMatriculaAlunoPrograma)
                            this.insereMatriculaAlunoPrograma(objMatriculaAlunoPrograma).then(res => {
                              if ((index + 1) == this.fastSteps.step2.modulo.lista.length && !errosModulos) {
                                this.statusGeracaoMatricula.insercao_modulos.status = "ok"
                                this.statusGeracaoMatricula.insercao_modulos.message = this.fastSteps.step2.modulo.lista.length + " módulo(s) cadastrados(s)"
                              }                 
                            }).catch(() => {
                              errosModulos++
                              this.statusGeracaoMatricula.insercao_modulos.status = "error"
                              this.statusGeracaoMatricula.insercao_modulos.message = "Matrícula gerada mas ocorreu um erro ao cadastrar os módulos. Informe o id_matricula #" + this.fastSteps.step3.matricula.id_matricula + " ao administrador do sistema."
                              this.showPrevButton() 
                              this.showNextButton()
                            })
                          }
                        })
                    })
                    
                    } else if (this.fastSteps.step2.plano == "unidade") {
                      this.statusGeracaoMatricula.insercao_unidades.status = "executing"
                      this.statusGeracaoMatricula.insercao_unidades.message = "Cadastrando..."

                      // Gravar em [fastead].[fast_secretaria_matricula_aluno_unidade]
                      let errosUnidades = 0;
                      this.fastSteps.step2.unidade.lista.forEach((m, index) => {            
                          this.fastSecretariaUnidadesTotal.forEach(t => {
                            if (m == t.id_secretaria_curso_programa_unidade) {
                              let objMatriculaAlunoUnidade = {
                                id_plataforma: this.$route.params.id_plataforma,
                                fast_secretaria_matricula_aluno_unidade: {            
                                  id_matricula: this.fastSteps.step3.matricula.id_matricula,
                                  id_programa_turma_unidade: t.id_programa_turma_unidade
                                }
                              }                  
                              console.log("objMatriculaAlunoUnidade", objMatriculaAlunoUnidade)
                              this.insereMatriculaAlunoUnidade(objMatriculaAlunoUnidade).then(res => {
                                if ((index + 1) == this.fastSteps.step2.unidade.lista.length && !errosUnidades) {
                                  this.statusGeracaoMatricula.insercao_unidades.status = "ok"
                                  this.statusGeracaoMatricula.insercao_unidades.message = this.fastSteps.step2.unidade.lista.length + " unidades(s) cadastradas(s)"
                                }                 
                              }).catch(() => {
                                errosUnidades++
                                this.statusGeracaoMatricula.insercao_modulos.status = "error"
                                this.statusGeracaoMatricula.insercao_modulos.message = "Matrícula gerada mas ocorreu um erro ao cadastrar as unidades curriculares. Informe o id_matricula #" + this.fastSteps.step3.matricula.id_matricula + " ao administrador do sistema."
                                this.showPrevButton() 
                                this.showNextButton()                                
                              })
                            }
                          })
                      })          
                    }

                    if (!errosModulos && !errosUnidades) {
                      this.statusGeracaoMatricula.insercao_parcelas.status = "ok"
                      this.statusGeracaoMatricula.insercao_parcelas.message = this.fastSteps.step3.plano.parcelas.length + " parcelas(s) cadastradas(s)"                        
                    } 
                    
                    this.statusGeracaoMatricula.iniciou = false
                    this.showNextButton()

                  } else {
                    if (json.description.split(",").length) {
                      this.fastErros = json.description.split(",")
                    } else {
                      this.fastErros.push(json.description)
                      console.log("this.fastErros", this.fastErros)
                    }            
                    this.showModal('modalErrosRequisicao')
                    this.showPrevButton() 
                    this.showNextButton()
                    this.statusGeracaoMatricula.iniciou = false
                    this.statusGeracaoMatricula.matricula_gerada.status = "error"
                    this.statusGeracaoMatricula.matricula_gerada.message = "Erro ao gerar matrícula. Consulte um administrador do sistema."

                  }
                }        
              })
              .catch(e => {
                this.exibeToasty(e, "error")
                this.statusGeracaoMatricula.matricula_gerada.status = "error"
                this.statusGeracaoMatricula.matricula_gerada.message = "Erro ao gerar matrícula. Consulte um administrador do sistema."
                this.showPrevButton() 
                this.showNextButton() 
                this.statusGeracaoMatricula.iniciou = false
              })    
                      
            }
          }       
        }
      })
      .catch(e => {
        this.statusGeracaoMatricula.matricula_gerada.status = "error"
        this.statusGeracaoMatricula.matricula_gerada.message = e
        this.exibeToasty(e, "error")
        this.showPrevButton() 
        this.showNextButton() 
      })

        
    },
    async insereMatriculaAluno(obj) {
      return new Promise(async (resolve, reject) => {    
        this.promiseInserirFastApi(obj, "fast_secretaria_matricula_aluno").then(res =>  {     
          console.log("insereMatriculaAluno", res)     
          if (res.length) {
              this.exibeToasty("Matrícula gerada com sucesso", "success");
              resolve(res)
              return true
            } else {
              this.exibeToasty("Erro ao gerar matrícula", "error");
              reject(false)
              return false;
            }               
          }).catch(e => {
            this.exibeToasty("Erro ao gerar matrícula", "error");            
            reject(false)
            return false;
          })
      });
    },
    async insereMatriculaAlunoPrograma(obj) {
      return new Promise(async (resolve, reject) => {        
        this.promiseInserirFastApi(obj, "fast_secretaria_matricula_aluno_programa").then(res =>  {     
        console.log("insereMatriculaAlunoPrograma", res)     
        if (res.length) {
            this.exibeToasty("Módulo cadastrado com sucesso", "success");
            resolve(res)
            return true
          } else {
            this.exibeToasty("Erro ao cadastrar módulo", "error");
            reject(false)
            return false;
          }               
        }).catch(e => {
          this.exibeToasty("Erro ao cadastrar módulo", "error");            
          reject(false)
          return false;
        })       
      });
    },
    async insereMatriculaAlunoUnidade(obj) {
      return new Promise(async (resolve, reject) => {        
        this.promiseInserirFastApi(obj, "fast_secretaria_matricula_aluno_unidade").then(res =>  {     
        console.log("insereMatriculaAlunoProgramaUnidade", res)     
        if (res.length) {
            this.exibeToasty("Unidade curricular cadastrado com sucesso", "success");
            resolve(res)
            return true
          } else {
            this.exibeToasty("Erro ao cadastrar unidade curricular", "error");
            reject(false)
            return false;
          }               
        }).catch(e => {
          this.exibeToasty("Erro ao cadastrar unidade curricular", "error");            
          reject(false)
          return false;
        })       
      });
    },
    async insereTesourariaInvoice(obj) {
      return new Promise(async (resolve, reject) => {        
        this.promiseInserirFastApi(obj, "fast_tesouraria_invoice").then(res =>  {     
          console.log("insereTesourariaInvoice", res)     
          if (res.length) {
              this.exibeToasty("Invoice gerado com sucesso", "success");
              resolve(res)
              return true
            } else {
              this.exibeToasty("Erro ao cadastrar invoice", "error");
              reject(false)
              return false;
            }               
          }).catch(e => {
            this.exibeToasty("Erro ao cadastrar invoice", "error");            
            reject(false)
            return false;
          })       
      });
    },
    async insereTesourariaInvoiceParcela(obj) {
      return new Promise(async (resolve, reject) => {        
        this.promiseInserirFastApi(obj, "fast_tesouraria_invoice_parcela").then(res =>  {     
          console.log("insereTesourariaInvoiceParcela", res)     
          if (res.length) {
              //this.exibeToasty("Parcelas geradas com sucesso", "success");
              resolve(res)
              return true
            } else {
              //this.exibeToasty("Erro ao cadastrar parcelas", "error");
              reject(false)
              return false;
            }               
          }).catch(e => {
            //this.exibeToasty("Erro ao cadastrar parcelas", "error");            
            reject(false)
            return false;
          })          
      });
    },    
    getSecretariaContratosCurso(id_plataforma, id_secretaria_curso){
      if (!this.fastSecretariaContratosCurso.length) {
        this.fastSteps.step5.loading = true;
        this.promiseGetFastApi("api/fast_secretaria_contrato/lista_curso", "id_plataforma=" + id_plataforma + "&id_secretaria_curso=" + id_secretaria_curso).then(obj => {
          console.log("getSecretariaContratos", obj);
          if (obj.length) {
            this.fastSecretariaContratosCurso = obj;
            this.fastContratoEscolhido.id_contrato = obj[0].id_contrato
          }
          this.fastSteps.step5.loading = false;
        }).catch(e => {
          console.log(e);
        })
      }      
    },
    geraPdfContrato() {   
      console.log("this.fastContratoEscolhido", this.fastContratoEscolhido)
      if (!this.fastContratoEscolhido.id_contrato) {
        this.$store.state.fastCarregando = false;
        this.exibeToasty("Selecione um template de contrato", "error");
      } else {
        this.$store.state.fastCarregando = true;
        this.fastSecretariaContratosCurso.forEach(c => {
          if (c.id_contrato == this.fastContratoEscolhido.id_contrato) {
            this.fastContratoEscolhido.html_template = c.html_template;
          }
        });   
        
        if (!this.fastContratoEscolhido.html_template) this.exibeToasty("Erro ao buscar template de contrato", "error");
        else {
          let contrato = this.fastContratoEscolhido.html_template;
          let erros = [];

          if (this.fastContratoEscolhido.html_template.indexOf("%nome_aluno%") != "-1" && !this.fastPessoa.first_name) erros.push("O nome do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%nome_aluno%") != "-1" && !this.fastPessoa.last_name) erros.push("O sobrenome do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%cpf_aluno%") != "-1" && !this.fastPessoa.cpf_cnpj) erros.push("O CPF/CNPJ do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%logradouro_aluno%") != "-1" && !this.fastPessoa.logradouro) erros.push("O endereço do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%numero_aluno%") != "-1" && !this.fastPessoa.numero) erros.push("O número do endereço do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%bairro_aluno%") != "-1" && !this.fastPessoa.bairro) erros.push("O bairro do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%cep_aluno%") != "-1" && !this.fastPessoa.cep) erros.push("O CEP do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%cidade_aluno%") != "-1" && !this.fastPessoa.cidade) erros.push("O cidade do aluno não foi cadastrado")
          if (this.fastContratoEscolhido.html_template.indexOf("%uf_aluno%") != "-1" && !this.fastPessoa.uf) erros.push("O estado do aluno não foi cadastrado")

          if (erros.length) {
            this.$store.state.fastCarregando = false;
            erros.forEach(e => this.exibeToasty(e, "error"))
          } else {
            this.promiseGetFastApi("api/fast_plataforma_usuario/busca_pessoa_secretaria", "id_pessoa=" + this.fastSteps.step4.id_pessoa_responsavel + "&id_plataforma=" + this.$route.params.id_plataforma).then(obj => {       
              let objPessoa = JSON.parse(obj)
              if (objPessoa.id_pessoa) {    
                  if (this.fastContratoEscolhido.html_template.indexOf("%nome_responsavel%") != "-1" && !objPessoa.first_name) erros.push("O nome do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%nome_responsavel%") != "-1" && !objPessoa.last_name) erros.push("O sobrenome do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%cpf_responsavel%") != "-1" && !objPessoa.cpf_cnpj) erros.push("O CPF/CNPJ do aluno responsavel foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%logradouro_responsavel%") != "-1" && !objPessoa.logradouro) erros.push("O endereço do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%numero_responsavel%") != "-1" && !objPessoa.numero) erros.push("O número do endereço do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%bairro_responsavel%") != "-1" && !objPessoa.bairro) erros.push("O bairro do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%cep_responsavel%") != "-1" && !objPessoa.cep) erros.push("O CEP do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%cidade_responsavel%") != "-1" && !objPessoa.cidade) erros.push("O cidade do responsavel não foi cadastrado")
                  if (this.fastContratoEscolhido.html_template.indexOf("%uf_responsavel%") != "-1" && !objPessoa.uf) erros.push("O estado do responsavel não foi cadastrado")

                  if (!erros.length) {
                    contrato = contrato.replaceAll(
                    "%nome_aluno%",
                      this.fastPessoa.first_name.trim() + " " + this.fastPessoa.last_name.trim()
                    );
                    contrato = contrato.replaceAll(
                    "%nome_responsavel%",
                      objPessoa.first_name.trim() + " " + objPessoa.last_name.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%cpf_aluno%",
                      this.fastPessoa.cpf_cnpj.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%cpf_responsavel%",
                      objPessoa.cpf_cnpj.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%logradouro_aluno%",
                      this.fastPessoa.logradouro.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%logradouro_responsavel%",
                      objPessoa.logradouro.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%numero_aluno%",
                      this.fastPessoa.numero.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%numero_responsavel%",
                      objPessoa.numero.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%bairro_aluno%",
                      this.fastPessoa.bairro.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%bairro_responsavel%",
                      objPessoa.bairro.trim() 
                    );                
                    contrato = contrato.replaceAll(
                      "%cep_aluno%",
                      this.fastPessoa.cep.trim().replaceAll("-","")
                    );                    
                    contrato = contrato.replaceAll(
                      "%cep_responsavel%",
                      objPessoa.cep.trim().replaceAll("-","")
                    );
                    contrato = contrato.replaceAll(
                      "%cidade_aluno%",
                      this.fastPessoa.cidade.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%cidade_responsavel%",
                      objPessoa.cidade.trim() 
                    );
                    contrato = contrato.replaceAll(
                      "%uf_aluno%",
                      this.fastPessoa.uf.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%uf_responsavel%",
                      objPessoa.uf.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%complemento_aluno%",
                      this.fastPessoa.complemento.trim()
                    );
                    contrato = contrato.replaceAll(
                      "%complemento_responsavel%",
                      objPessoa.complemento.trim()
                    );

                    console.log("this.fastSteps", this.fastSteps)

                    // Valores
                    let valor_total = 0
                    valor_total += this.fastSteps.step3.matricula.valor_matricula ? parseFloat(this.fastSteps.step3.matricula.valor_matricula) : 0
                    valor_total += this.fastSteps.step3.invoice.total ? this.fastSteps.step3.invoice.total : 0

                    //https://github.com/portujs/extenso.js
                    let extenso = require('extenso')

                    console.log("valor_total", valor_total)

                    // Valor total = matricula + curso
                    valor_total = valor_total.toFixed(2)
                    let valor_total_extenso = extenso(valor_total.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_total%", `R$ ${this.formataPreco(valor_total)} (${valor_total_extenso})`);

                    // Valor da matrícula
                    let valor_matricula = this.fastSteps.step3.matricula.valor_matricula ? parseFloat(this.fastSteps.step3.matricula.valor_matricula) : 0
                    valor_matricula = valor_matricula.toFixed(2)
                    let valor_matricula_extenso = extenso(valor_matricula.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_matricula%", `R$ ${this.formataPreco(valor_matricula)} (${valor_matricula_extenso})`);

                    // Valor total do curso = soma todas as parcelas
                    let valor_total_curso = 0;
                    if (this.fastSteps.step3.plano.parcelas.length) {
                      valor_total_curso = this.fastSteps.step3.plano.parcelas.map(o=>o.valor_parcela).reduce((a,c)=>a+c);
                    }
                    valor_total_curso = valor_total_curso.toFixed(2)
                    let valor_total_curso_extenso = extenso(valor_total_curso.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_total_curso%", `R$ ${this.formataPreco(valor_total_curso)} (${valor_total_curso_extenso})`);

                    // Número de parcelas
                    let numero_parcelas = this.fastSteps.step3.plano.parcelas.length;
                    contrato = contrato.replaceAll("%numero_parcelas%", `${numero_parcelas}`);

                    // Valor da parcela
                    let valor_parcela = 0
                    if (this.fastSteps.step3.plano.parcelas.length) {
                      valor_parcela = this.fastSteps.step3.plano.parcelas[this.fastTesourariaInvoice.parcelas.length - 1].valor_parcela                      
                    }
                    valor_parcela = valor_parcela.toFixed(2)
                    let valor_parcela_extenso = extenso(valor_parcela.replace(".", ","), { mode: 'currency', currency: { type: 'BRL' }, locale: 'br', number: { decimalSeparator: 'comma' } })
                    contrato = contrato.replaceAll("%valor_parcela%", `R$ ${this.formataPreco(valor_parcela)} (${valor_parcela_extenso})`);

                    // Dia da parcela
                    let dia_parcela = ""
                    if (this.fastSteps.step3.plano.parcelas.length) {
                      if (this.fastSteps.step3.plano.parcelas[0].data_vencimento) dia_parcela = this.fastSteps.step3.plano.parcelas[0].data_vencimento.split("T")[0].split("-")[2]
                    }
                    contrato = contrato.replaceAll(
                      "%dia_parcela%",
                      dia_parcela.trim()
                    );

                    // Gera pdf
                    this.fastContratoEscolhido.html_template = contrato;
                    this.$refs.html2Pdf.generatePdf();

                    this.$store.state.fastCarregando = false;
                  } else {                    
                    erros.forEach(e => this.exibeToasty(e, "error"))
                    this.$store.state.fastCarregando = false;
                  }  
              } else {
                this.exibeToasty("Erro ao buscar dados do responsável", "error");
                this.$store.state.fastCarregando = false;
              }
            }).catch(e => {
              console.log(e);
              this.exibeToasty("Erro ao buscar dados do responsável", "error");
              this.$store.state.fastCarregando = false;
            })
          }                  
        }
      }      
    },  
    // beforeDownload -> não utilizado mais
    async beforeDownload ({ html2pdf, options, pdfContent }) {
        await html2pdf().set(options).from(pdfContent).toPdf().get('pdf').then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()
            console.log("pdfContent", pdfContent)
            for (let i = 1; i <= totalPages; i++) {
                pdf.setPage(i)
                pdf.setFontSize(10)
                pdf.setTextColor(150)
                pdf.text('Página ' + i + ' de ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
            } 
        }).save()
    },    
    async atualizaMatriculaAluno(obj) {
      return new Promise(async (resolve, reject) => {    
        this.promiseAtualizarFastApi(obj, "fast_secretaria_matricula_aluno").then(res =>  {     
          console.log("insereMatriculaAluno", res)     
          if (res.length) {
              this.exibeToasty("Matrícula atualizada com sucesso", "success");
              resolve(res)
              return true
            } else {
              this.exibeToasty("Erro ao atualizar matrícula", "error");
              reject(false)
              return false;
            }               
          }).catch(e => {
            this.exibeToasty("Erro ao atualizar matrícula", "error");            
            reject(false)
            return false;
          })
      });
    },  
    fileValidation(e) {
      return false;
    },
    fileSuccess(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        this.fastSteps.step5.path_contrato = result.url;
        this.fastSteps.step3.matricula.path_contrato = result.url;        
      }
    },  
    fileSuccessEmail(rootFile, file, message, chunk) {
      let result = JSON.parse(JSON.parse(message));
      if (!result.error) {
        let fast_secretaria_contrato_aluno = {
          id_usuario_logado: this.fastPessoa.Usuario_cadastro,
          id_plataforma: this.$route.params.id_plataforma,
          email_recuperacao: this.fastPessoa.Email,
          path_contrato: result.url
        }

        console.log("fast_secretaria_contrato_aluno", fast_secretaria_contrato_aluno)

        this.$store.state.fastCarregando = true;
        // Controller fast_secretaria_contrato
        this.promiseInserirFastApi(fast_secretaria_contrato_aluno, "fast_secretaria_contrato_aluno").then((res) => {
          if (res == "Email enviado") {
            this.exibeToasty("Email enviado com sucesso", "success");
            this.hideModal("modalEnviarContratoEmail")
          } else {
            this.exibeToasty(res, "error");
          }
          this.$store.state.fastCarregando = false;
        })
        .catch(e => {
          this.exibeToasty(e, "error")
          this.$store.state.fastCarregando = false;
        });
      }
    }, 
    retornaTotalMatricula(valor_matricula, desconto_matricula, outros_valores){
      let valor_total = 0
      if (valor_matricula) {
        valor_total = parseFloat(valor_matricula)
        if (desconto_matricula) valor_total -= parseFloat(desconto_matricula)
        if (outros_valores) valor_total += parseFloat(outros_valores)
      }
      return this.formataPreco(valor_total)
    },
    async validaQuintoPasso() {
      return new Promise(async (resolve, reject) => {
        // Não é mais obrigatório o envio previo do contrato
        //if (this.fastSteps.step5.path_contrato && this.fastSteps.step3.invoice.id_matricula) {   
        if (this.fastSteps.step3.invoice.id_matricula) { 

          this.$store.state.fastCarregando = true;
          this.fastSteps.step3.matricula.contrato_gerado = true
          this.atualizaMatriculaAluno(this.fastSteps.step3.matricula).then((res) => {
            this.fastSteps.step3.invoice.parcelas = []
            this.getTesourariaInvoice(this.fastSteps.step3.invoice.id_matricula);

            res.forEach(m => {
              if (m.id_matricula == this.fastSteps.step3.matricula.id_matricula) {
                console.log("Matrícula", m)
                this.fastSteps.step3.matricula = m
                if (m.id_invoice) {
                  this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista", "id_invoice=" + m.id_invoice).then(res => {        
                    console.log("getTesourariaInvoiceParcelasTaxaMatricula", res)
                    if (res.length) {     
                      this.fastSteps.step6.matricula.matriculaInvoiceParcela = res;              
                    } else {
                      this.exibeToasty("Nenhuma parcela para a taxa de matrícula", "error");
                    }      
                    this.fastSteps.step6.matricula.loading = false  
                  }).catch(e => {
                    console.log(e);
                    this.exibeToasty("Erro ao recuperar parcelas", "error");
                    this.fastSteps.step6.matricula.loading = false 
                  })
                }
              }
            })

            this.$store.state.fastCarregando = false;
            
            //this.getSecretariaMatriculaAluno(this.$route.params.id_plataforma, this.$route.params.id_pessoa_aluno);
            this.hidePrevButton();
            resolve(true);
            return true;
          }).catch(e => {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Erro ao atualizar matrícula", "error");
            reject(true)
            return false
          })   
        } else {
          this.exibeToasty("Gere a matrícula e faça upload do contrato assinado", "error");
          reject(true)
          return false
        }          
      });
    },
    // Sexto passo
    getTesourariaInvoice(id_matricula){
      
      //if (!this.fastSteps.step3.invoice.parcelas.length) {
        this.promiseGetFastApi("api/fast_tesouraria_invoice/lista", "id_plataforma=" + this.$route.params.id_plataforma + "&id_matricula=" + id_matricula).then(json => {        
          console.log("getTesourariaInvoice", json)
          if (json.length) {          
            json.forEach(e => {
              if (e.id_invoice == this.fastSteps.step3.invoice.id_invoice) {
                e.parcelas = []          
                this.fastSteps.step3.invoice = e;
              }
            })  
            console.log("this.fastSteps.step3.invoice.id_invoice", this.fastSteps.step3.invoice.id_invoice)
            this.promiseGetFastApi("api/fast_tesouraria_invoice_parcela/lista", "id_invoice=" + this.fastSteps.step3.invoice.id_invoice).then(json2 => {        
              console.log("getTesourariaInvoiceParcelas", json2)
              if (json2.length) {     
                this.fastSteps.step6.invoice.parcelas = json2;              
              } else {
                this.exibeToasty("Nenhuma parcela para esse invoice", "error");
              }      
              this.fastSteps.step6.invoice.loading = false  
            }).catch(e => {
              console.log(e);
              this.exibeToasty("Erro ao recuperar parcelas", "error");
              this.fastSteps.step6.invoice.loading = false 
            })
          } else {
            this.exibeToasty("Nenhum registro invoice para essa matrícula", "error");
            this.fastSteps.step6.invoice.loading = false 
          }        
        }).catch(e => {
          console.log(e);
          this.exibeToasty("Erro ao recuperar invoice", "error");
          this.fastSteps.step6.invoice.loading = false 
        })
      //}      
    },
    getSecretariaMatriculaAluno(id_plataforma, id_pessoa_aluno){
      if (!this.fastSteps.step3.matricula.id_matricula) {
        this.promiseGetFastApi("api/fast_secretaria_matricula_aluno/lista", "id_plataforma=" + id_plataforma + "&id_programa=0&id_pessoa_aluno=" + id_pessoa_aluno).then(obj => {
          console.log("getSecretariaMatriculaAluno", obj);
          if (obj.length) {
            obj.forEach(m => {
              if (m.id_matricula == this.fastSteps.step3.matricula.id_matricula) {
                console.log("Matrícula", m)
                this.fastSteps.step3.matricula = m
              }
            })
            
          }
          else {

          }
        }).catch(e => {
          console.log(e);
        })
      }      
    },
    async validaSextoPasso() {
      return new Promise(async (resolve, reject) => {
          resolve(true);
          return true;
      });
    },
    exibeModalWhatsApp(boleto){
      this.fastLinkWhats.path_boleto = boleto.path_boleto
      this.fastLinkWhats.parcela = boleto.parcela
      this.fastLinkWhats.valor_parcela_total = boleto.valor_parcela_total
      this.fastLinkWhats.data_vencimento = boleto.data_vencimento
      this.fastLinkWhats.nome_plataforma = boleto.nome_plataforma
      this.fastLinkWhats.tipo = boleto.tipo
      this.fastLinkWhats.telefone_responsavel = this.fastSteps.step4.telefone_responsavel
      this.fastLinkWhats.telefone_aluno = this.fastPessoa.telefone

      this.showModal('modalWhatsApp')
    },
    retornaLinkWhats(telefone, path_boleto, parcela, valor_parcela_total, data_vencimento, nome_plataforma, tipo){
      let link = ""
      let tel = telefone
      if (tel) {
        tel = telefone.replaceAll("-", "").replaceAll("(", "").replaceAll(")", "").replaceAll(" ", "")
        tel = `55${tel}`
        if (path_boleto) {
          let text = path_boleto
          if (parcela && valor_parcela_total && data_vencimento && nome_plataforma && tipo) {
            let referente = ""
            if (tipo == "I") {
              referente = "à cobrança"
            } else if (tipo == "M"){
              referente = "à taxa de matrícula"
            } else if (tipo == "C"){
              referente = "ao curso definido"
            } else {
              referente = "à cobrança"
            }
            text = `Olá, segue a parcela ${parcela} do carnê no valor de R$ ${this.formataPreco(valor_parcela_total)}. Cobrança referente ${referente}, emitida por ${nome_plataforma}, com vencimento para ${this.formataDataT(data_vencimento)}. Acesse o boleto pelo link: ${path_boleto}`
          }
          link = `https://api.whatsapp.com/send?phone=${tel}&text=${text}`

        } else {
          //this.exibeToasty("Boleto não cadastrado", "error")
        }
      } else {
        //this.exibeToasty("Telefone não registrado", "error")
      }
      return link
    },
    async onComplete() {
      return new Promise(async (resolve, reject) => {  
        window.location.href = "/loja-virtual/" + this.$route.params.id_plataforma 
      });
    },    
    handleValidation: function (isValid, tabIndex) {
      console.log("Tab: " + tabIndex + " valid: " + isValid);
    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg;
    },
    hidePrevButton() {
      document.querySelectorAll(".wizard-footer-left")[0].classList.add("d-none");
    },
    showPrevButton() {
      document.querySelectorAll(".wizard-footer-left")[0].classList.remove("d-none");
    },
    hideNextButton() {
      document.querySelectorAll(".wizard-footer-right")[0].classList.add("d-none");
    },
    showNextButton() {
      document.querySelectorAll(".wizard-footer-right")[0].classList.remove("d-none");
    },

    // Checkout
    /*
    async validateFirstTab() {
      return new Promise(async (resolve, reject) => {
        if (this.usuarioLogado) {
          resolve(true);
          return true;
        }

        if (!this.$store.state.fastCarrinho.length) {
          this.$toast.open({
            message: "Você não possui nenhum curso no carrinho",
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
          reject("");
          return false;
        }

        if (this.formLogin.email_login && this.formLogin.senha) {
          if (!this.validaEmail(this.formLogin.email_login)) {
            this.$toast.open({
              message: "Email de login inválido",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }

          this.$store.state.fastCarregando = true;
          this.formLogin.senha = btoa(this.formLogin.senha);
          let options = {
            headers: {
              "Content-Type": "application/json; charset=utf-8",
            },
            method: "POST",
            body: `username=${this.formLogin.email_login}&password=${this.formLogin.senha}&grant_type=password`,
          };

          // Autentica na API e captura o token
          try {
            let resp = await fetch(
              settings.endApiFastEad + "api/token",
              options
            );
            let json = await resp.json();
            //console.log(json);
            if (!json.error) {
              // Armazena token na sessão
              sessionStorage.setItem(
                settings.fastSessaoToken,
                JSON.stringify(json)
              );

              // Busca usuário
              resp = await fetch(
                settings.endApiFastEad +
                  "api/fast_plataforma_usuario/busca_usuario",
                this.fastAjaxOptions("GET")
              );
              json = await resp.json();
              if (!json.error) {
                // Armazena informações do usuário na sessão
                sessionStorage.setItem(settings.fastSessaoUsuario, json);
                location.reload();
                resolve(true);
              } else {
                // loading
                this.$store.state.fastCarregando = false;
                this.$toast.open({
                  message: "Login ou senha incorretos",
                  type: "error",
                  duration: 5000,
                  dismissible: true,
                  position: "bottom-right",
                });
                reject("");
              }
            } else {
              // loading
              this.$store.state.fastCarregando = false;
              this.$toast.open({
                message: "Login ou senha incorretos",
                type: "error",
                duration: 5000,
                dismissible: true,
                position: "bottom-right",
              });
              reject("");
            }
          } catch (e) {
            // loading
            this.$store.state.fastCarregando = false;
            this.$toast.open({
              message: "Login ou senha incorretos",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
          }
        } else if (
          this.formCadastro.nome_usuario &&
          this.formCadastro.email_cadastro &&
          this.formCadastro.senha1 &&
          this.formCadastro.senha2
        ) {
          if (this.formCadastro.nome_usuario.split(" ").length < 2) {
            this.$toast.open({
              message: "Insira seu nome e sobrenome",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.validaEmail(this.formCadastro.email_cadastro)) {
            this.$toast.open({
              message: "Email de cadastro inválido",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (this.formCadastro.senha1.length < 6) {
            this.$toast.open({
              message: "Sua senha deve possuir no mínimo 6 caracteres",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (this.formCadastro.senha1 != this.formCadastro.senha2) {
            this.$toast.open({
              message: "As senhas digitadas não coincidem",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }

          // loading
          this.$store.state.fastCarregando = true;
          // Verifica se email não ta cadastrado
          this.verificaEmailConcurseiro(this.formCadastro.email_cadastro)
            .then(async () => {
              const First_name = this.formCadastro.nome_usuario.split(" ")[0];
              const Last_name = this.formCadastro.nome_usuario
                .split(" ")
                .slice(1)
                .join(" ");
              const Usuario = {
                First_name: First_name,
                Last_name: Last_name,
                Password: this.formCadastro.senha1,
                Email: this.formCadastro.email_cadastro,
              };
              console.log("Usuario", Usuario);
              // Cadastra pessoa
              this.cadastraUsuarioConcurseiro(Usuario)
                .then((e) => {
                  sessionStorage.setItem(settings.fastSessaoUsuario, e);
                  sessionStorage.setItem(
                    settings.fastSessaoToken,
                    JSON.stringify({ access_token: JSON.parse(e).token })
                  );
                  location.reload();
                  resolve(true);
                })
                .catch((e) => {
                  // loading
                  this.$store.state.fastCarregando = false;
                  reject("Erro ao cadastrar pessoa");
                });
            })
            .catch((e) => {
              // loading
              this.$store.state.fastCarregando = false;
              reject("Esse email já foi cadastrado no sistema");
            });
        } else {
          this.$toast.open({
            message: "Insira seu login ou realize seu cadastro",
            type: "error",
            duration: 5000,
            dismissible: true,
            position: "bottom-right",
          });
          reject("");
        }
      });
      //return this.$refs.formLogin.validate();
    },
    validaEmail(email) {
      let re = /\S+@\S+\.\S+/;
      return re.test(email);
    },
    validateSecondTab() {
      return new Promise(async (resolve, reject) => {
        if (this.$store.state.fastPagamento.tipo == "credit_card") {
          if (!this.$store.state.fastPagamento.credit_card.cpf_cnpj) {
            this.$toast.open({
              message: "O CPF/CNPJ é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.credit_card.number) {
            this.$toast.open({
              message: "O número do cartão é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.credit_card.name) {
            this.$toast.open({
              message: "O nome impresso no cartão é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.credit_card.expirycard) {
            this.$toast.open({
              message: "A data de expiração é obrigatória",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.credit_card.cvccard) {
            this.$toast.open({
              message: "O número CVC é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.credit_card.parcela) {
            this.$toast.open({
              message: "Selecione a parcela",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          resolve(true);
        } else if (this.$store.state.fastPagamento.tipo == "bank_slip") {
          if (!this.$store.state.fastPagamento.bank_slip.nome_razao) {
            this.$toast.open({
              message: "O nome é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.sobrenome_fantasia) {
            this.$toast.open({
              message: "O sobrenome é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.telefone) {
            this.$toast.open({
              message: "O telefone é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.cpf_cnpj) {
            this.$toast.open({
              message: "O CPF/CNPJ é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.sexo) {
            this.$store.state.fastPagamento.bank_slip.sexo = "M";
          }
          if (
            !this.$store.state.fastPagamento.bank_slip.datanascimento_abertura
          ) {
            this.$toast.open({
              message: "A data de nascimento é obrigatória",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.cep) {
            this.$toast.open({
              message: "O CEP é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.logradouro) {
            this.$toast.open({
              message: "O endereço é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.numero) {
            this.$toast.open({
              message: "O número é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.bairro) {
            this.$toast.open({
              message: "O bairro é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.cidade) {
            this.$toast.open({
              message: "O cidade é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }
          if (!this.$store.state.fastPagamento.bank_slip.uf) {
            this.$toast.open({
              message: "O estado é obrigatório",
              type: "error",
              duration: 5000,
              dismissible: true,
              position: "bottom-right",
            });
            reject("");
            return false;
          }

          resolve(true);
        }
      });
    },
    async verificaCEP() {
      let regex = /\d{5}-\d{3}/g;
      if (regex.test(this.$store.state.fastPagamento.bank_slip.cep)) {
        try {
          let resp = await fetch(
            "https://viacep.com.br/ws/" +
              this.$store.state.fastPagamento.bank_slip.cep.replace("-", "") +
              "/json/",
            { method: "GET" }
          );
          let json = await resp.json();
          if (json.cep) {
            this.$store.state.fastPagamento.bank_slip.bairro = json.bairro;
            this.$store.state.fastPagamento.bank_slip.cidade = json.localidade;
            this.$store.state.fastPagamento.bank_slip.logradouro =
              json.logradouro;
            this.$store.state.fastPagamento.bank_slip.uf = json.uf;
          }
        } catch (e) {
          console.log(e);
        }
      }
    },
    trocaFormaPagamento(formaPagamento) {
      this.$store.state.fastPagamento.tipo = formaPagamento;
    },
    async onComplete() {
      return new Promise(async (resolve, reject) => {
        this.$store.state.fastCarregando = true;
        this.efetuaCompra()
          .then((e) => {
            if (e.erro == "False") {
              console.log("e", e);
              this.$store.state.fastCarrinho = [];
              this.setFastSessao(settings.fastSessaoCarrinho, null);

              e.venda = true;
              e.forma_pagamento = this.$store.state.fastPagamento.tipo;
              this.setFastSessao(
                settings.fastSessaoCheckoutFinal,
                JSON.stringify(e)
              );

              console.log(
                "this.getFastSessao(settings.fastSessaoCheckoutFinal)",
                this.getFastSessao(settings.fastSessaoCheckoutFinal)
              );

              window.location.href =
                "/loja-virtual/" +
                this.$store.state.fastPermissoes.id_plataforma +
                "/checkout_end";
            } else {
              this.$store.state.fastCarregando = false;
              reject("Falha ao efetuar compra");
            }
          })
          .catch((e) => {
            this.$store.state.fastCarregando = false;
            reject("Falha ao efetuar compra");
          });
      });
    },
    setLoading: function (value) {
      this.loadingWizard = value;
    },
    handleValidation: function (isValid, tabIndex) {
      console.log("Tab: " + tabIndex + " valid: " + isValid);
    },
    handleErrorMessage: function (errorMsg) {
      this.errorMsg = errorMsg;
    },
    validateAsync: function () {      
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (this.count < 1) {
            this.count++;
            reject(
              "This is a custom validation error message. Click next again to get rid of the validation"
            );
          } else {
            this.count = 0;
            resolve(true);
          }
        }, 1000);
      });
    },
    async efetuaCompra() {
      return new Promise(async (resolve, reject) => {
        let ids_produto_vindi = [];
        let promo_code = "";
        this.$store.state.fastCarrinho.forEach((c) => {
          ids_produto_vindi.push(c.id_produto_vindi);
          if (c.promo_code && promo_code == "") promo_code = c.promo_code;
        });
        let endereco = {
          Uf: this.$store.state.fastPagamento.bank_slip.uf,
          Cep: this.$store.state.fastPagamento.bank_slip.cep,
          Logradouro: this.$store.state.fastPagamento.bank_slip.logradouro,
          Bairro: this.$store.state.fastPagamento.bank_slip.bairro,
          Cidade: this.$store.state.fastPagamento.bank_slip.cidade,
          Complemento: this.$store.state.fastPagamento.bank_slip.complemento,
          Numero: this.$store.state.fastPagamento.bank_slip.numero,
        };
        let telefone = {
          Tipo: 0,
          _Telefone: this.$store.state.fastPagamento.bank_slip.telefone,
        };
        let Pessoa = {
          Id_Pessoa: this.retornaSessao(settings.fastSessaoUsuario).Id_Pessoa
            ? this.retornaSessao(settings.fastSessaoUsuario).Id_Pessoa
            : 0,
          Nome_razao: this.retornaSessao(settings.fastSessaoUsuario).First_name,
          Sobrenome_fantasia: this.retornaSessao(settings.fastSessaoUsuario)
            .Last_name,
          Cpf_cnpj: this.$store.state.fastPagamento.credit_card.cpf_cnpj
            ? this.$store.state.fastPagamento.credit_card.cpf_cnpj
            : this.$store.state.fastPagamento.bank_slip.cpf_cnpj,
          Datanascimento_abertura: this.$store.state.fastPagamento.bank_slip
            .datanascimento_abertura,
          Sexo: this.$store.state.fastPagamento.bank_slip.sexo,
        };
        let usuario = {
          Id_Usuario: this.retornaSessao(settings.fastSessaoUsuario).Id_Usuario,
          Email: this.retornaSessao(settings.fastSessaoUsuario).Email,
          Pessoa: Pessoa,
        };
        let pagamento_cartao = this.$store.state.fastPagamento.credit_card;
        let VendaProduto = { Id_Produto: 0 };
        let tabela_venda_preco = {
          Id_Tabela_Preco: 0,
          CodigoPreco: 0,
          ValorBase: "",
          ValorFinal: "",
          Periodo: "",
          Recorrente: "",
          VendaProduto: VendaProduto,
        };
        let VendaCurso = {
          forma_pagamento: this.$store.state.fastPagamento.tipo,
          ids_produto_vindi: ids_produto_vindi.join(","),
          endereco: endereco,
          telefone: telefone,
          tabela_venda_preco: tabela_venda_preco,
          usuario: usuario,
          pagamento_cartao: pagamento_cartao,
          promo_code: promo_code,
        };

        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_curso/compra_produto_vindi",
            this.fastAjaxOptions("POST", JSON.stringify(VendaCurso))
          );
          let json = await resp.json();
          console.log(JSON.parse(json));
          resolve(JSON.parse(json));
        } catch (e) {
          console.log("Erro", e);
          reject("Falha ao efetuar compra");
        }
      });
    },
    async verificaEmailConcurseiro(email) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiConcurseiro +
              "api/questoes/verificaEmail?email=" +
              email,
            this.fastAjaxOptions("GET")
          );
          let json = await resp.json();
          if (json.length) {
            reject("Email já cadastrado");
          } else {
            console.log("Email ok... " + email);
            resolve(true);
          }
        } catch (e) {
          reject(e);
        }
      });
    },
    async cadastraUsuarioConcurseiro(Usuario) {
      return new Promise(async (resolve, reject) => {
        try {
          let resp = await fetch(
            settings.endApiFastEad + "api/fast_plataforma_usuario/register",
            this.fastAjaxOptions("POST", JSON.stringify(Usuario))
          );
          let json = await resp.json();
          console.log("cadastraUsuarioConcurseiro", json);
          resolve(json);
        } catch (e) {
          reject(e);
        }
      });
    },
    formatarMoeda(elemento) {
      let value = elemento.toString();
      if (value.indexOf(".") !== -1 && value.indexOf(",") !== -1) {
        if (value.indexOf(".") < value.indexOf(",")) {
          //inglês
          return parseFloat(value.replace(/,/gi, ""));
        } else {
          //português
          return parseFloat(value.replace(/./gi, "").replace(/,/gi, "."));
        }
      } else {
        return parseFloat(value);
      }
    },
    */
  },
};
</script>

<style scope>
.vue-form-wizard .wizard-tab-content {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
@media (max-width: 992px) {
   
  .wizard-nav-pills li {
    flex-basis: 100% !important;
  }
  .wizard-progress-with-circle, .wizard-nav-pills, .wizard-header {
    display: none !important;
  }
}
</style>
